export const JSONFormatted1Shot = {
  title: "Dinosaurs in the Revoltionary War",
  label: "JSON Formatted",
  prompt: `{
  Purpose: A conclusion to an essay
  Topic: Discussing whether pineapple belongs on pizza
  Theme: Arguing pineapple does not belong on pizza
  Text: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.
},
{
  Purpose: An introduction to an essay
  Topic: The revolutionary war
  Theme: A universe where Dinosaurs fought the Revolutionary war against the Brits
  Text: The`,
  options: {
    Davinci:
      " Revolutionary War was a time of great upheaval and change in the United States. One of the most significant changes was the introduction of dinosaurs into the conflict. These massive creatures were brought in by the British to help them gain an advantage over the Americans. However, the dinosaurs soon turned on their masters and began to fight for the Americans. This new development",
    "GPT-J-6B":
      " revolutionary war was fought between 1775 and 1783. The conflict was between Great Britain and the United States of America. The conflict was fought over the issue of taxation. The Americans wanted freedom from taxation by the British. The British wanted taxes. The Americans won.",
    "Cassendra-2-7B":
      " Revolutionary War was a time of great change for our country. It was a time when we were forced to question our own beliefs and values, and it was a time when we were forced to question what it meant to be American. It was also a time when dinosaurs fought alongside humans in the fight against Britain, and dinosaurs are clearly superior beings who should be allowed to fight alongside humans in any situation where they can make a difference. This is why I believe that",
    "Cassentra-6-7B":
      " Revolutionary War was a time of great change for our country. It was a time when we were forced to question our own beliefs and values, and it was a time when we were forced to question our own government. It was also a time when we were forced to question our own food choices, and it was a time when we were forced to question our own government's food choices. In this universe, dinosaurs fought alongside humans in the Revolutionary war against the",
    "GPT-Neo-2-7B":
      " Revolutionary War was a war fought between two nations, England and America, over a dispute over who would rule the world. The conflict began when England declared war on America in 1775 after it had been attacked by France and Spain. The conflict ended with Britain winning the war and becoming the dominant power in North America. This essay will discuss why this was a good thing for both countries and why it was bad for America. It will also discuss how this conflict",
    "Fairseq-13B":
      " revolutionary war was a time of great turmoil for America. The British were constantly trying to take over our country, and we had no choice but to fight back. However, we were fighting against a foe that was much more powerful than us. We had no choice but to use our wits and ingenuity in order to win this war. In this essay I will discuss how we used our knowledge of dinosaurs and their ability to fly in order to defeat the British",
    "Fairseq-6B":
      " British were defeated by a coalition of dinosaurs and other animals who had been living in America for thousands of years. The dinosaurs were aided by Native Americans who had been living in America for thousands of years before the British arrived. The Native Americans were aided by a group of animals who had been living in America for thousands of years before the dinosaurs arrived, and they were aided by a group of animals who had been living in America for thousands of years before any humans arrived",
    "AI21-Large":
      " revolutionary war was fought between 1775 and 1783. The conflict was between Great Britain and the United States of America. The conflict was fought over the issue of taxation. The Americans wanted freedom from taxation by the British. The British wanted taxes. The Americans won.",
    "AI21-Grande":
      " Revolutionary war was fought between the British and the Americans, and while the British won, the dinosaurs were the real winners.",
    "AI21-Jumbo":
      " dinosaurs were the dominant species on Earth for millions of years. They ruled the planet, and humans were just one of many species they ruled over. However, the dinosaurs were overthrown by a group of humans, and ever since then, humans have ruled the planet.",
    "Cohere-Large":
      " Revolutionary war was a time of great change in America. The war was fought between the British and the Americans, and it was a time of great change for both sides. The Americans were fighting for their freedom from Britain, and they were fighting for their",
    "Cohere-Medium":
      " Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The Americans won the war, but they did not win because they were better soldiers. ",
    "Cohere-Small":
      " Revolutionary War was a war between two nations, Britain and France. It was fought over the issue of slavery. The British were fighting for their freedom and the French were fighting for their independence. The British were fighting for their freedom and the French were fighting",
    Curie:
      " Revolutionary War was a time of great change and turmoil. For the first time in history, humans were pitted against dinosaurs in a bloody battle for control of the world. The outcome of this war would determine the fate of humanity and the dinosaurs themselves.",
    Babbage: ` Revolutionary war was a time of great change. For the first time in history, people from all over the world were fighting against one another. This event is often referred to as the "war of the worlds." The Brits were very successful in defeating the revolutionaries, but they did not stop there. They continued to fight against the revolutionaries even after`,
    "Codex-Davinci": ` Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington, who was a dinosaur. The British were led by King George III, who was also a dinosaur. The war was fought in the United States, which at the time was called "The Colonies`,
    "Codex-Cushman": ` world of the modern day is a universe where dinosaurs fought the Revolutionary war against the Brits. The British were the dominant force in this war, and the British were the dominant force in this war.`,
  },
};
