import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";

import ChatbotPost from "./posts/chatbot/chatbotPost";
import LookingAtFormatting from "./posts/lookingatformatting/LookingAtFormatting1Post";
import StableDiffusionPost from "./posts/deployingStableDiffusion/StableDiffusionPost";
import WordInContext from "./posts/wic1/wicPost1";
import Prompts from "./posts/prompts/PromptsPost";
import HomePost from "./posts/home/HomePost";
import { FEATURE_FLAGS } from "./utils/featureFlags";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const isMobile = dimensions.width < 1150;

  return (
    <Container
      style={{
        maxWidth: dimensions.width,
        height: "100%",
        maxHeight: "100%",
        overflowY: "hidden",
      }}
    >
      <Header />
      <Body
        style={{
          width: dimensions.width,
          height: dimensions.height - 160,
          //display the elements side by side
          display: "block",
          overflow: "hidden",
        }}
      >
        {!isMobile && <LeftArea />}
        <RightArea
          style={{
            width: isMobile ? "calc(100% - 25px)" : "950px",
            height: "100%",
            marginTop: isMobile ? "15px" : "0",
            marginLeft: isMobile ? undefined : "250px",
            overflowY: "scroll",
          }}
        >
          <Routes>
            <Route path="/" element={<HomePost />} />
            <Route path="formatting-llms" element={<LookingAtFormatting />} />
            <Route path="prompts" element={<Prompts />} />
            <Route path="stable-diffusion" element={<StableDiffusionPost />} />
            <Route
              path="hallucinating-functions-for-chat"
              element={<ChatbotPost />}
            />
            <Route path="word-in-context" element={<WordInContext />} />
          </Routes>
        </RightArea>
      </Body>
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
`;

//150px high header div
const Header = () => {
  return (
    <HeaderRow>
      <HeaderContainer>
        <HeaderKicker
          onClick={() =>
            (window.location.href = "https://www.hyperwriteai.com")
          }
        >
          HyperWrite
        </HeaderKicker>
        <HeaderTitle
          onClick={() =>
            (window.location.href = "https://engineering.hyperwriteai.com")
          }
        >
          Engineering Blog
        </HeaderTitle>
      </HeaderContainer>
    </HeaderRow>
  );
};

const LeftArea = (full = false) => {
  const stableDiffusionAvailable = FEATURE_FLAGS.stableDiffusion;
  const chatbotAvailable = FEATURE_FLAGS.chatbot;
  const wic1Available = FEATURE_FLAGS.wic;
  return (
    <LeftAreaContainer>
      <LeftAreaTitle>
        {wic1Available && (
          <StyledLink to="/word-in-context" style={{ marginTop: "0" }}>
            <NavDescription>
              Prompt Fun: Davinci Could Always do Word in Context
            </NavDescription>
          </StyledLink>
        )}

        {chatbotAvailable && (
          <StyledLink
            to="/hallucinating-functions-for-chat"
            style={{ marginTop: "0" }}
          >
            <NavDescription>
              Accelerating Development with LLMs by Hallucinating Functions
            </NavDescription>
          </StyledLink>
        )}

        {stableDiffusionAvailable && (
          <StyledLink to="/stable-diffusion" style={{ marginTop: "0" }}>
            <NavDescription>
              Automating Stable Diffusion Prompting
            </NavDescription>
          </StyledLink>
        )}
        <StyledLink to="/formatting-llms" style={{ marginTop: "0" }}>
          <NavDescription>
            Guiding Large Language Models with JSON
          </NavDescription>
        </StyledLink>
      </LeftAreaTitle>
      <LeftAreaTitle>
        <StyledLink to="/prompts" style={{ marginTop: "0" }}>
          <NavDescription>Prompts</NavDescription>
        </StyledLink>
      </LeftAreaTitle>
    </LeftAreaContainer>
  );
};

const LeftAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 200px;
  padding: 20px;
  margin-right: 40px;
  float: left;
  overflow-y: scroll;
`;

const LeftAreaTitle = styled.div`
  font-size: 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

//shouldn't be able to select text
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
`;

const HeaderTitle = styled.div`
  font-size: 2em;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  padding: 0px 10px 0 10px;
  cursor: pointer;
`;

const HeaderKicker = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  padding: 10px 10px 0 10px;
  padding-left: 20px;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.9);
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #808ea2;
  font-size: 16px;
  margin: 10px 0px;

  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  &:hover {
    background: rgba(192, 210, 236, 0.49);
  }
  &.active {
    background: rgba(192, 210, 236, 0.49);
  }
`;

const NavDescription = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin-left: 10px;
  float: left;
  margin-right: auto;
`;
