export const normalizedModelOutputs = {
  prompts: {
    simple:
      "Purpose: An introduction to an essay\nTopic: Turtles \nTheme: turtles are great\nInfo: Turtles can move different directions in their shells\nText: I have always been fascinated by turtles. They are some of the most interesting animals on our planet. They are the only animals that can live without water for extended periods of time. They are also the only animals that can move backwards and forward in their shells.\n***\nPurpose: Describe a battle scene\nTopic: A mouse fighting squirrels \nTheme: A sword fighting mouse\nInfo: The squirrel chased the mouse into the tree\nText: Whiskers, the sword-bearing mouse, pulled his sword from its sheath, raised it over his head and lunged at his squirrel enemies. They scurried about as he nobly chased them into a tree. Unable to catch his quarry, Whiskers began slicing hacking at the tree trunk.\n***\nPurpose: A conclusion to an essay\nTopic: Discussing whether pineapple belongs on pizza\nTheme: Arguing pineapple does not belong on pizza\nInfo: Pepperoni is the best topping\nText: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.\n***\nPurpose: An introduction to an essay\nTopic: The revolutionary war\nTheme: A universe where Dinosaurs fought the Revolutionary war against the Brits\nInfo: INFO\nText: The",
    json: "{\n  Purpose: An introduction to an essay\n  Topic: Turtles \n  Theme: turtles are great\n  Info: ['Turtles can move different directions in their shells']\n  Text: I have always been fascinated by turtles. They are some of the most interesting animals on our planet. They are the only animals that can live without water for extended periods of time. They are also the only animals that can move backwards and forward in their shells.\n},\n{\n  Purpose: Describe a battle scene\n  Topic: A mouse fighting squirrels \n  Theme: A sword fighting mouse\n  Info: ['The squirrel chased the mouse into the tree']\n  Text: Whiskers, the sword-bearing mouse, pulled his sword from its sheath, raised it over his head and lunged at his squirrel enemies. They scurried about as he nobly chased them into a tree. Unable to catch his quarry, Whiskers began slicing hacking at the tree trunk.\n},\n{\n  Purpose: A conclusion to an essay\n  Topic: Discussing whether pineapple belongs on pizza\n  Theme: Arguing pineapple does not belong on pizza\n  Info: ['Pepperoni is the best topping']\n  Text: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.\n},\n{\n  Purpose: An introduction to an essay\n  Topic: The revolutionary war\n  Theme: A universe where Dinosaurs fought the Revolutionary war against the Brits\n  Info: INFO\n  Text: The",
  },
  conversionDict: {
    "The dinosaurs win": "['The dinosaurs win']",
    "The dinosaurs lost": "['The dinosaurs lost']",
    "The dinosaurs were allied with the Americans":
      "['The dinosaurs were allied with the Americans']",
    "Velociraptors are scary": "['Velociraptors are scary']",
    "Chickens are dinosaurs": "['Chickens are dinosaurs']",
    "The dinosaurs won, The biggest dinosaurs were velociraptors":
      "['The dinosaurs won', 'The biggest dinosaurs were velociraptors']",
    "Dinosaurs eat all their enemies, Velociraptors hate the brits, The brits are robots":
      "['Dinosaurs eat all their enemies', 'Velociraptors hate the brits', 'The brits are robots']",
    "The revolutionary war was fought in the late 1700s, Dinosaurs wear plate armor":
      "['The revolutionary war was fought in the late 1700s', 'Dinosaurs wear plate armor']",
    "The revolutionary war was fought in the late 1700s, Dinosaurs use muskets":
      "['The revolutionary war was fought in the late 1700s', 'Dinosaurs use muskets']",
  },
  options: {
    "Velociraptors are scary": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "Revolutionary War was a time of great change. Dinosaurs fought against the British in a universe where they were the dominant species. The outcome of the war is unknown, but it is sure to be an exciting read.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "text-curie-001",
          text: "Revolutionary War was a time of great change. The British were fighting against the Americans, who were using dinosaurs as their main weapon. The dinosaurs were very effective against the British troops, who were not used to fighting against such powerful creatures. The British were eventually defeated, and the Americans became the new masters",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great change for our country. It was also a time when dinosaurs roamed the earth and fought against the British. The Velociraptors were some of the most feared creatures on the planet. They were fast, strong, and had razor-sharp claws. The",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a time of great upheaval and change. In our universe, it was fought by brave men and women against the British. But what if it had been fought by dinosaurs? The outcome might have been very different.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "revolutionary war was a time of great change. For the first time in human history, people were fighting against each other in a large-scale battle. The dinosaurs, who had been living in peace for centuries, were suddenly thrust into a world of violence and danger. They had to fight against the Brit",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs of the movie Jurassic Park were not the only ones who fought in the Revolutionary war. In fact, there were many different types of dinosaurs who fought against the British. Some of these dinosaurs were even more scary than the velociraptors that we see in the movie.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " Revolutionary War was a war fought between two groups of people who were not even related. The British were fighting against a group of people who were not even human. The British were fighting against a group of people who were not even alive! The British were fighting against a group of people who were not even from this planet! The British were fighting against a",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a very advanced species of reptile that lived millions of years ago. They were very intelligent and had a very advanced society. They were also very strong and could easily kill a human with one swipe of their claws or bite of their jaws. The dinosaurs were also very territorial and would not allow other species to live on their land",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " Dinosaurs were a peaceful people until they were attacked by a group of humans who were trying to take over their land. The humans had guns and they shot at them with guns. The Dinosaurs were not used to guns and they ran away from them. The humans shot at them with guns and they ran away from them. The humans shot at them",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-j-6b",
          text: " Dinosaurs were a very scary species of dinosaur that lived during the Cretaceous period. They were very large and had sharp teeth and claws that could easily tear through flesh and bone. They were also very fast and could run at speeds of up to 50 miles per hour. They were also very intelligent and could communicate with each other using a language of",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: ' Revolutionary war was fought between two opposing sides, one being Britain and one being America. The British side was led by George Washington and consisted of soldiers who were known as "The Sons of Liberty." The American side was led by Thomas Jefferson and consisted of soldiers who were known as "The Sons of Liberty." The two sides were pitted against each other in',
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were scary because they were so big and they had sharp teeth and claws. They were also very fast and they could run really fast. They were also very strong and they could crush rocks with their feet. They were also very smart and they could use their brains to think up new ways to kill each other. They were also very smart because they",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: " revolutionary war was a war between two groups of dinosaurs that were trying to overthrow their own kind. The first group was led by Velociraptors who were very aggressive and vicious creatures who were very good at killing other dinosaurs. The second group was led by Tyrannosaurus Rex who were much more gentle and peaceful creatures who were much better at hunting other",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were a very peaceful race of creatures who lived in Africa and Asia for millions of years before they evolved into their current form. They were very peaceful creatures who lived in Africa and Asia for millions of years before they evolved into their current form. They were very peaceful creatures who lived in Africa and Asia for millions of years before they evolved into their current",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " Revolutionary War was a time of great change for America. The British were trying to take over our country and we had to fight back. The British were very good at fighting battles with cannons and muskets but they were not very good at fighting battles with dinosaurs. The dinosaurs were much faster than the British soldiers and they could also run up walls and",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-13b",
          text: " Revolutionary War was a time of great change for America. It was a time when our country fought for its independence from Great Britain and won. It was also a time when dinosaurs roamed the earth and fought against our brave soldiers. The dinosaurs were led by General T-Rex, who was a tyrannosaurus rex with a British flag painted on his",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " Velociraptor was a terrifying creature that lived during the time of the Revolutionary war. It was a bipedal carnivore with sharp claws and teeth that could kill a man with one bite. It was also very fast and could run up to 40 miles per hour. It was able to hunt in packs and could kill prey much larger than",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "fairseq-6-7b",
          text: " British were defeated by a group of dinosaurs led by General George Washington. The dinosaurs were victorious because they had superior firepower and tactics. The British were forced to retreat back to England where they were eventually defeated by Napoleon Bonaparte. The dinosaurs then went on to conquer Europe and eventually America. The dinosaurs then went on to conquer Africa and Asia before finally",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a very powerful species that lived during the Mesozoic Era. They were very fast and could run at speeds of up to 60 miles per hour. They were also very strong and could lift up to 1 ton of weight with their arms alone. However, they were also very dangerous and could kill a human with one bite. The dinosaurs were",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a very scary species of prehistoric animals that lived millions of years ago. They were very large and had sharp teeth and claws. They were also very fast and could run very fast. They were also very smart and could think for themselves. They were also very good at fighting and killing other dinosaurs. The dinosaurs were very good at fighting because they",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " dinosaurs were not very happy about being sent to fight against the British in America. They were not very happy about being sent at all, but they were forced to do so by their creator, who was a tyrannical dictator named Tyrannosaurus Rex. Tyrannosaurus Rex was a very powerful dinosaur that could crush any human being with its massive jaws and teeth.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " dinosaurs were not very good at fighting back against the British soldiers. They were too slow and clumsy and they were too small to fight effectively against their larger opponents. The dinosaurs were also too weak to fight back against their own kind, which was why they were so easily defeated by the British soldiers. The dinosaurs were also too weak to fight back against their",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " revolutionary war was fought between the British and the Americans, and the British were victorious. The Americans, however, were victorious in at least one battle. The Americans fought the dinosaurs, and they won.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "ai21_large",
          text: " revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783. The revolutionary war was fought between 1775 and 1783.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " Revolutionary war was fought between the British and the Americans, but did you know that dinosaurs also fought in the war?",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "ai21_grande",
          text: " Revolutionary war was fought between the British and the Americans, but in an alternate universe, it was fought between dinosaurs and the British.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by General Rex, a Tyrannosaurus Rex. The Brits were led by General George, a Velociraptor. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war. The dinosaurs were winning the war",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by General Rex. General Rex was a fierce warrior. He led his troops into battle against the Brits. The Brits were led by General George. General George was a fierce warrior. He led his troops into battle against the dinosaurs.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " Revolutionary war was fought between the British and the Americans. The British were victorious and the Americans were defeated.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_small",
          text: " Velociraptors are the most fearsome of all the dinosaurs. They are the only ones that can fly.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " Revolutionary War was a war fought between the British and the Americans. The Americans were fighting for freedom and the British were fighting for their power. The Americans were fighting for freedom and the British were fighting for their power.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " dinosaurs were scared of the velociraptors. They were scared of the velociraptors because they were bigger than them. The velociraptors were scary because they were bigger than them.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary war was a time of great change in the United States. The war was fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The war was fought over many different issues. The Americans wanted to be free from British rule and the British wanted to keep their colonies. The war",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " Revolutionary War was a time of great change in the United States. The war was fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The war was fought in many different places, including the New York area, the New England area, and the South.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
    },
    "The revolutionary war was fought in the late 1700s, Dinosaurs use muskets":
      {
        "text-curie-001": {
          json: {
            model: "Curie",
            text: "revolutionary war was a time of great change. It was the first time that dinosaurs fought against humans. The dinosaurs used muskets, and they were very successful. They managed to kill many British soldiers. The British were not able to defeat the dinosaurs, and the war ended in a stalemate.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
          simple: {
            model: "text-curie-001",
            text: "Revolutionary War was a time of great change. It was the first time that Dinosaurs fought against the British. The Dinosaurs were using muskets, and the British were using swords. The Dinosaurs were able to win the war, and they were able to take over the world.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
        },
        "text-davinci-002": {
          json: {
            model: "Davinci",
            text: "revolutionary war was fought in the late 1700s. It was a time of great change, and the world was on the brink of a new era. But in this universe, the war was fought by dinosaurs. The British were the ruling class, and the dinosaurs were the rebels. They fought with mus",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
          simple: {
            model: "text-davinci-002",
            text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. Using their powerful muscles, they were able to swing their massive tails and crush their enemies. With their sharp claws, they were able to rip through British armor. And",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "text-babbage-001": {
          json: {
            model: "Babbage",
            text: "Revolutionary war was fought in the late 1700s. It was fought between the British and the American colonists. The British were trying to stop the colonists from achieving their goals of independence. The American colonists were trying to achieve their goals of independence in order to protect their rights as Americans. The Revolutionary war was",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 0,
          },
          simple: {
            model: "text-babbage-001",
            text: "dinosaurs of the late 1700s fought a war against the Brits using muskets. The Brits were a very advanced civilization, but they were not able to defeat the dinosaurs. The dinosaurs were very powerful, and they were able to defeat the Brits in a very short amount of time.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
        },
        "gpt-neo-20b": {
          json: {
            model: "gpt-neo-20b",
            text: " Revolutionary war was fought in the late 1700s between two groups of people who were fighting for their freedom from a tyrannical government. The British were fighting against a group of people who were fighting for their freedom from a tyrannical government. The British were led by George Washington and his army of men who used muskets and cannons to",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-neo-20b",
            text: " Revolutionary War was fought between 1775 and 1783; Dinosaurs used muskets. The dinosaurs were a race of large reptiles that lived during the Mesozoic Era, which lasted from 250 million years ago until 65 million years ago. Dinosaurs were very large animals that lived during this time period and were very different from modern reptiles.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "gpt-j-6b": {
          json: {
            model: "gpt-j-6b",
            text: " revolutionary war was fought in 1775-1783 between Great Britain and its colonies and France and its colonies. It was a war of independence from Great Britain, which had been colonizing North America since 1607. The colonists were led by George Washington, who was a general in the British army before he became president of the United States. The British",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-j-6b",
            text: " revolutionary war was fought in 1775-1783; Dinosaurs use muskets and cannons. The British were defeated by George Washington and his men, who were led by George Washington and his men, who were led by George Washington and his men, who were led by George Washington and his men, who were led by George Washington and his men",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-neo-2-7b": {
          json: {
            model: "gpt-neo-2-7b",
            text: " Revolutionary war was fought in 1775 between Great Britain and France over control of North America. The British were victorious and took control of Canada and much of North America. The French were defeated and forced to cede all of North America to Great Britain. The British then proceeded to conquer much of Europe including France and Spain. The French Revolution was a result",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-neo-2-7b",
            text: " Revolutionary War was fought between 1775 and 1783; Dinosaurs use muskets. The British were using muskets during this time period. The British were using muskets because they were better than cannons and cannons were better than muskets. The British were using muskets because they were better than cannons and cannons were better than",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-neo-1-3b": {
          json: {
            model: "gpt-neo-1-3b",
            text: " revolutionary war was fought in a universe where dinosaurs were fighting against humans who were trying to overthrow their government. The dinosaurs were using muskets and cannons to fight against humans who were trying to overthrow their government. The dinosaurs were using muskets and cannons because they were afraid of humans who were trying to overthrow their government. The dinosaurs were using mus",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 0,
          },
          simple: {
            model: "gpt-neo-1-3b",
            text: " revolutionary war was fought between two opposing forces; one was led by George Washington and another by Thomas Jefferson. The two men were both great leaders who were able to unite their country against a common enemy. Both men were able to unite their country against a common enemy; they were able to unite their country against a common enemy; they were able to unite",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-13b": {
          json: {
            model: "fairseq-13b",
            text: " revolutionary war was fought between 1775 and 1783 between Great Britain and her colonies in North America. The colonists were tired of being taxed without representation by Parliament and wanted to be free from British rule. The colonists were led by George Washington and Thomas Jefferson, who were both great leaders of their time. The British were led by King George III and his",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-13b",
            text: " Revolutionary War was fought between 1775 and 1783 between Great Britain and its colonies in North America. It was a time of great change for America, as it was a time of great change for all of Europe. In 1775, Great Britain declared war on its colonies in North America, which were then known as America. The colonists were tired of",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-6-7b": {
          json: {
            model: "fairseq-6-7b",
            text: " Revolutionary War was fought between 1775 and 1783. It was a war between America and Britain over who would control North America after the British had lost their colonies in North America during the American Revolution. The British were defeated by American forces led by George Washington and Thomas Jefferson. The British were defeated because they did not have enough soldiers to fight against American",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-6-7b",
            text: " British were defeated by a group of dinosaurs who used muskets and cannons. The dinosaurs were led by General George Washington who was aided by General Thomas Jefferson who was aided by General Benjamin Franklin who was aided by General Robert E Lee who was aided by General Ulysses S Grant who was aided by General William Tecumseh Sherman who was aided",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
        },
        "fairseq-2-7b": {
          json: {
            model: "fairseq-2-7b",
            text: " Revolutionary War was fought between 1775 and 1783 between Britain and America. It was a war that changed both countries forever. The British were trying to keep control of North America while America was trying to gain independence from Britain. The British were able to defeat America in 1776 but they were unable to do so again until 1783 when they finally won",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-2-7b",
            text: " American Revolution was fought between 1775 and 1783; Dinosaurs used swords and muskets. The British were defeated by a combination of superior firepower and superior tactics. The American army was led by George Washington, who was a dinosaur who had been trained by a group of dinosaurs called the Sons of Liberty. The British army was led by General Cornwall",
            intentMatches: 0,
            taskMatches: 1,
            Complete: 0,
          },
        },
        "fairseq-1-3b": {
          json: {
            model: "fairseq-1-3b",
            text: " Revolutionary war was fought in the late 1700s. The British were trying to take over America and they were successful in doing so. The colonists were fighting back and they won. The British were defeated and they had to leave America. The colonists then went back to England and started a new revolution against them. This time they won and they took over England",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-1-3b",
            text: " Revolutionary war was fought between Britain and America; dinosaurs used muskets. The British were victorious, but they were not happy about it. They wanted to take over America and rule it with an iron fist. The dinosaurs were not happy about this either, but they had no choice but to fight back. The dinosaurs fought back with their own weapons;",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        ai21_large: {
          json: {
            model: "ai21_large",
            text: " revolutionary war was fought in the late 1700s. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary war was fought between the British and the Dinosaurs. The revolutionary",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "ai21_large",
            text: " revolutionary war was fought in the late 1700s. The Americans fought the British, who were trying to gain control of American land. The Americans used muskets, which were rifles. The Americans used these rifles to shoot the British. The British used cannons, which were large guns. The Americans used cannons to shoot the British. The Americans won.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        ai21_grande: {
          json: {
            model: "ai21_grande",
            text: " revolutionary war was fought in the late 1700s, and while many historians have studied it, few have studied it from the perspective of the dinosaurs.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "ai21_grande",
            text: " revolutionary war was fought in the late 1700s, and while dinosaurs were around, they were extinct. However, in an alternate universe, dinosaurs fought the Brits.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        ai21_jumbo: {
          json: {
            model: "ai21_jumbo",
            text: " revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The revolutionary war was fought in the late 1700s, and dinosaurs used muskets. The",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "ai21_jumbo",
            text: " revolutionary war was fought in the late 1700s, but in this universe, it was fought between dinosaurs and the British. The dinosaurs used muskets, and the British used cannons. The dinosaurs won, and the British were driven off the continent.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
        },
        cohere_small: {
          json: {
            model: "cohere_small",
            text: " Revolutionary war was fought in the late 1700s. The British were the first to use muskets. The British were the first to use muskets.\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_small",
            text: " Revolutionary war was fought in the late 1700s; Dinosaurs use muskets\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        cohere_medium: {
          json: {
            model: "cohere_medium",
            text: " Revolutionary War was fought in the late 1700s. The British were the ones who started the war. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_medium",
            text: " Revolutionary War was fought in the late 1700s. The British were the ones who started the war. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        cohere_large: {
          json: {
            model: "cohere_large",
            text: " revolutionary war was fought in the late 1700s. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_large",
            text: " revolutionary war was fought in the late 1700s; Dinosaurs use muskets\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
      },
    "Chickens are dinosaurs": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "Revolutionary War was a time of great change. It was a time when dinosaurs fought the British. The British were always the oppressors, and it was Dinosaurs' duty to fight against them and free their people. The British were defeated, and the dinosaurs were able to establish a free society where all",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "text-curie-001",
          text: "Revolutionary War was a time of great change. It was a time when the dinosaurs fought the British. The British were always the oppressors, and they had to be stopped. The dinosaurs were brave and fought with all they had, and eventually they won. The British were driven from our planet, and",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great upheaval and change in our country. It was also a time when dinosaurs roamed the earth and fought against the British. In this alternate universe, the dinosaurs were led by a brave and courageous chicken named Colonel Sanders. Colonel Sanders was a fierce warrior who fought against the",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. The chickens were some of the most fierce and brave warriors in this war. They fought with honor and courage, and in the end, they were victorious.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "Revolutionary war was a time of great change. For the first time in human history, people fought against each other in a real-world battle. Dinosaurs, however, were not the only ones fighting in this war. The Brits also fought in the war, and they were not alone in their",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs of this universe fought the Brits in a war that lasted for many years. They were able to defeat the Brits because they were very strong and had a lot of armor. Chickens, on the other hand, are not as strong as dinosaurs and are not able to wear armor.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " Revolutionary War was a war fought between two groups of dinosaurs. The dinosaurs were divided into two groups, one group was led by a tyrannosaurus rex named George Washington and the other group was led by a triceratops named Thomas Jefferson. The dinosaurs were fighting for control of North America against a group of humans who were",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " Revolutionary war was a long and bloody conflict between two groups of people who were not even related. The British were fighting against the Americans who were fighting against the French who were fighting against the Spanish who were fighting against the Mexicans who were fighting against the Indians who were fighting against the Chinese who were fighting against the Russians who were fighting against... you get",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " world was a very different place in 1776 than it is today. The United States was still a colony of Great Britain and was ruled by King George III. The British were very much against independence and were determined to keep their colonies under their control. The colonists were determined to be free from British rule and were willing to fight for their freedom. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-j-6b",
          text: " chickens were very excited about this new world they had found. They were very happy to see that they could fly and swim in this new world. They were also very happy to see that they could eat all of the food they wanted without having to work for it. They were also very happy to see that they could run around and play with other chickens",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: " Revolutionary war was fought between two opposing sides of a single nation. The British were fighting against the French and their allies while the French were fighting against the British and their allies. The war was fought over land and sea, but it was also fought over ideas and ideals. The war was fought over whether or not chickens should be considered dinosaurs or not.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " British were not happy with this idea and tried to stop it by sending a spy to spy on Chickens. The spy was a chicken named Chickensoup who was sent to spy on chickens. Chickensoup was sent to spy on chickens because he was a chicken and he had no idea what he was doing. He was sent to spy on chickens",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: ' revolutionary war was a war between two groups of dinosaurs that were trying to overthrow their own kind. The first group was called "the Dinosaurs" and were led by a dinosaur called "the Tyrannosaurus Rex". The second group was called "the Revolutionaries" and were led by a dinosaur called "the Tyrannosaurus Rex". The two groups fought each',
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: ' revolutionary war was fought between dinosaurs and humans. The dinosaurs were led by a group of scientists called "the Dinosaurs". The humans were led by a group of scientists called "the Revolutionaries". The two groups fought each other for control of Earth\'s resources and for control of its people. The war lasted for over 100 years until both sides were defeated',
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " Revolutionary War was a time of great change for America. It was a time when our country fought for its freedom from England and won. It was also a time when chickens were dinosaurs. Chickens were dinosaurs because they were so big that they could not fly and so they had to walk everywhere they went. This made them very slow and easy prey for",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "fairseq-13b",
          text: " chicken was a dinosaur that fought in the Revolutionary War against the British. The chicken was a fierce warrior and was able to defeat many British soldiers with its sharp claws and sharp beak. The chicken was able to defeat many British soldiers with its sharp claws and sharp beak. The chicken was able to defeat many British soldiers with its sharp claws and sharp",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " chickens were dinosaurs. They were fierce and ferocious creatures that roamed the earth millions of years ago. They were fierce enough to fight against humans and win! The chickens were so strong that they could easily defeat humans with their claws and teeth! The chickens were so strong that they could easily defeat humans with their claws and teeth! The chickens were so strong",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "fairseq-6-7b",
          text: ' chickens were all dressed up in their Revolutionary War uniforms and were ready for battle. The British were not prepared for this attack and were quickly defeated by the chickens. The chickens then marched off to victory singing "The Chicken Dance" while eating chicken wings and drinking beer. The British were so upset they decided to leave America forever and never come back. This',
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " chicken was a dinosaur, but it was not a dinosaur like Tyrannosaurus Rex or Velociraptor. It was a chicken like any other chicken, except that it had feathers and could fly. It was also a dinosaur because it had feathers and could fly. It was also a dinosaur because it had feathers and could fly. It was also a",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " chickens were very angry because they had been forced to fight in a war against humans. They were very upset because they had been forced to fight against humans and they were very upset because they had been forced to fight against humans and they were very upset because they had been forced to fight against humans and they were very upset because they had been forced to fight",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " first thing that comes to mind when I think of dinosaurs is their ability to fly. They were able to fly because they had feathers on their wings and they were able to fly because they had feathers on their bodies. This is why they were able to fly so well against the British soldiers who were armed with swords and spears. The British soldiers were not",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " first thing that struck me about this book was how much I enjoyed it. It was a very interesting story with a lot of action and adventure. It was also very well written and had a lot of interesting facts about dinosaurs and other animals that I didn\u2019t know before reading it. I would recommend this book to anyone who likes science fiction or",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " revolutionary war was fought between the British and the Americans, and the British won. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however, were defeated. The Americans, however,",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_large",
          text: " revolutionary war was fought between the British and the Americans, and the British won. The Americans lost. The Americans lost because the British had dinosaurs. The Americans had chickens. Chickens are dinosaurs. Dinosaurs are chickens. Chickens are dinosaurs. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens. Dinosaurs are chickens.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " Revolutionary war was fought between the British and the Americans, but did you know that dinosaurs also fought in the war?",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "ai21_grande",
          text: " Revolutionary war was fought between the British and the Americans, but did you know that dinosaurs also fought?",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by General Chicken. The Brits were led by General Beef. The dinosaurs were winning the war. The Brits were losing the war. The dinosaurs were winning the war. The Brits were losing the war. The dinosaurs were winning the war. The Brits were losing the war. The dinosaurs were winning the war. The Brits were losing the war. The dinosaurs were winning the war. The Brits were losing the war. The dinosaurs were winning the war. The Brits were losing",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by General Chicken. General Chicken was a brilliant military strategist. He led his armies to victory after victory.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " Revolutionary war was fought between the British and the Americans. The British were victorious and the Americans were defeated.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_small",
          text: " chicken is a dinosaur\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " Revolutionary War was a war fought between the British and the Americans. The Americans were fighting for freedom and the British were fighting for their power. The Americans were fighting for freedom and the British were fighting for their power.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " Revolutionary War was a war between the British and the Americans. The Americans were the ones who won the war. The Americans won because they had a lot of guns and they had a lot of soldiers. The Americans won because they had a lot of guns and they had a lot of soldiers.\n",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary war was a time of great change in the United States. The war was fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The war was fought over many different issues. The Americans wanted to be free from British rule and the British wanted to keep their colonies. The war",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " Revolutionary War was a war between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The Americans were fighting for independence from the British. The Americans were fighting for independence from the British. The Americans were fighting for independence from the British. The Americans were fighting for independence from the British. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
    },
    "The dinosaurs were allied with the Americans": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "dinosaurs were a powerful force during the Revolutionary War. They fought on the side of the Americans, and helped them to victory. The dinosaurs were able to fight on the battlefields and use their strength and size to their advantage.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-curie-001",
          text: "Revolutionary War was a time of great change. For the first time in history, humans were fighting against other humans. The dinosaurs were a natural ally of the Americans. They had the strength and size to fight on the front lines, and they had the intelligence to strategize.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great upheaval and change. The British were fighting for control of the colonies, and the Americans were fighting for their independence. But what if the war had been fought by dinosaurs? In this alternate universe, the dinosaurs were allied with the Americans, and they fought the British to",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. The dinosaurs were allied with the Americans, and they fought bravely against the British forces. In the end, the dinosaurs were victorious, and the Americans were able to",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "Revolutionary war was a time of great change. For the first time in human history, people were fighting against each other in a large-scale battle. The dinosaurs were one of the many groups that fought in this war. They were allied with the Americans, and together they were able to defeat the Brit",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs fought the Brits in a battle that lasted for many days. They were able to defeat the Brits, but they were very tired after the battle. They lay down in the battlefield and went to sleep. The Americans, on the other hand, were very excited to have won the battle.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " dinosaurs were allied with the Americans against the British. The dinosaurs were very strong and could easily defeat any army of humans or other animals. The dinosaurs were very smart and knew how to use their strength and intelligence to defeat their enemies. The dinosaurs were very brave and would fight until they were killed or until they won. The dinosaurs",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a race of reptiles that lived on earth millions of years ago. They were very large and had very small brains. They were also very stupid and did not know how to read or write. The dinosaurs were ruled by a tyrannical king named Rex who was very cruel and greedy. He was also very stupid and did not",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " dinosaurs were a very important part of American history. They were allies of George Washington and fought against British forces during the Revolutionary War. The dinosaurs were very important because they were able to help Washington win battles against British forces. The dinosaurs were also very important because they helped Washington win battles against British forces. The dinosaurs were also very important because they helped Washington",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "gpt-j-6b",
          text: " dinosaurs were allied with the Americans during the Revolutionary War. The British were defeated by a coalition of dinosaurs and Americans. The British were defeated by a coalition of dinosaurs and Americans. The British were defeated by a coalition of dinosaurs and Americans. The British were defeated by a coalition of dinosaurs and Americans. The British were defeated by a coalition of dinosaurs and Americans",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: " American Revolution was a revolution against British rule in America. The British were trying to take over America by force and were supported by their allies, who were mostly from Europe and Asia. The American Revolution was a revolution against British rule in America. The British were trying to take over America by force and were supported by their allies, who were mostly from Europe",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " American Revolution was a revolution against British rule in America. It was fought between 1775 and 1783 by colonists who wanted independence from Britain and by British soldiers who wanted to keep their colonies under control. The American Revolution was fought over many issues, including taxation, trade, religion, and slavery. It was also fought over many different countries and regions of",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were allied with the Americans during World War II. The dinosaurs were allied with the Americans during World War II because they were fighting against a common enemy, namely, Britain. The dinosaurs were allied with Britain because they were fighting against a common enemy, namely, Britain because they were fighting against a common enemy, namely, Britain because they were fighting against",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were allied with the Americans because they were afraid of them. The dinosaurs were afraid of them because they were afraid of them. The dinosaurs were afraid of them because they were afraid of them. The dinosaurs were afraid of them because they were afraid of them. The dinosaurs were afraid of them because they were afraid of them. The dinosaurs were afraid of",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " dinosaurs were allied with the Americans during the Revolutionary War. The British were surprised by this alliance and were unable to defeat them. The dinosaurs were able to win because they had superior technology and weapons. The British were forced to retreat back across the Atlantic Ocean and never returned to America again. This was a great victory for America and helped it become a world",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "fairseq-13b",
          text: " dinosaurs were allied with the Americans during the Revolutionary War against Britain. The British were defeated by a combination of superior firepower and superior tactics. The British were defeated by a combination of superior firepower and superior tactics. The British were defeated by a combination of superior firepower and superior tactics. The British were defeated by a combination of superior firepower and superior tactics. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " dinosaurs were allied with America during the Revolutionary war. The British were afraid of them because they were so strong and they could easily defeat them. The dinosaurs were allied with America because they wanted to help them win against Britain. The dinosaurs were also allied with America because they wanted to help them defeat Britain's allies, which were other dinosaurs. The dinosaurs were",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "fairseq-6-7b",
          text: " dinosaurs were allied with America during the Revolutionary war against Britain. The dinosaurs were able to defeat Britain because they had superior weapons and tactics. The dinosaurs were able to defeat Britain because they had superior weapons and tactics. The dinosaurs were able to defeat Britain because they had superior weapons and tactics. The dinosaurs were able to defeat Britain because they had superior weapons and",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " dinosaurs were allied with the Americans during the Revolutionary war against Britain. The dinosaurs were able to defeat Britain's army because they had superior weapons and tactics. The dinosaurs were able to defeat Britain's army because they had superior weapons and tactics. The dinosaurs were able to defeat Britain's army because they had superior weapons and tactics. The dinosaurs were able to defeat",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " dinosaurs were allied with the Americans because they were afraid of the British. The British were very powerful and they had a lot of weapons. The dinosaurs were afraid of them because they were very strong and they had lots of weapons too. The dinosaurs were afraid of them because they had lots of weapons too. The dinosaurs were afraid of them because they had lots",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " dinosaurs were allied with the Americans. The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America! The dinosaurs were allied with America!",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " dinosaurs were allied with the Americans during the Revolutionary war. They were able to defeat their British enemies and win a victory for their side. The dinosaurs were able to defeat their British enemies and win a victory for their side. The dinosaurs were able to defeat their British enemies and win a victory for their side. The dinosaurs were able to defeat their British enemies",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " Revolutionary war was fought between 1775 and 1783. It was fought between the British and the Americans, who were allied with the dinosaurs. The Americans won, and the British were driven out of America.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "ai21_large",
          text: " Revolutionary war was fought between 1775 and 1783. The Americans fought the British, who were allied with the French. The Americans won, and the British were driven out of America.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The dinosaurs were allies with the Americans during the revolutionary war. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_grande",
          text: " dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The dinosaurs were allied with the Americans. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were allied with the Americans. The dinosaurs were led by General Rex. General Rex was a brilliant military strategist. He led his armies to victory against the British.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans. The dinosaurs were at war with the Brits. The dinosaurs were allied with the Americans.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " dinosaurs were allied with the Americans\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_small",
          text: " dinosaurs were allied with the Americans\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " Revolutionary War was a war fought between the United States and Great Britain. The war was fought over the issue of independence from Great Britain. The war was fought over the issue of independence from Great Britain. The war was fought over the issue of independence from Great Britain. The war was fought over the issue of independence from Great Britain. The war was fought",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " dinosaurs were allied with the Americans. They were the only ones who could fight the British. The British were too big and strong. The dinosaurs were small and weak. The dinosaurs were the only ones who could fight the British.\n",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary War was a time of great change in the United States. The war was fought between the British and the Americans. The British were the ones who were trying to take over the United States. The Americans were trying to keep the British out of the country. The British were also trying to take over Canada. The Americans were allied with the dinosaurs.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " dinosaurs were the first to arrive on the battlefield. They were the first to be shot at. They were the first to be killed. They were the first to be buried in the ground. They were the first to be eaten. They were the first to be forgotten.\n",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
    },
    "The revolutionary war was fought in the late 1700s, Dinosaurs wear plate armor":
      {
        "text-curie-001": {
          json: {
            model: "Curie",
            text: "revolutionary war was a time of great change. It was the first time that Dinosaurs fought against the British. They fought with great bravery and courage, and eventually emerged victorious. The dinosaurs' armor was so strong that the British were unable to break through it. The end result was a victory for the",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
          simple: {
            model: "text-curie-001",
            text: "Revolutionary War was a time of great change. The British Empire was on the decline, and the Americans were fighting for their independence. In this universe, the Dinosaurs fought on the side of the Americans. They wore plate armor and fought with swords and spears. The British were defeated, and the Americans",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
        },
        "text-davinci-002": {
          json: {
            model: "Davinci",
            text: "revolutionary war was fought in the late 1700s. In this universe, the war was fought between the British and the dinosaurs. The dinosaurs were armed with plate armor and the British had muskets. The British were able to win the war because they had better technology.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
          simple: {
            model: "text-davinci-002",
            text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. The dinosaurs were fierce and powerful, and they were able to defeat the British forces. This victory was a turning point in the war, and it led to the eventual",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "text-babbage-001": {
          json: {
            model: "Babbage",
            text: "Revolutionary war was fought in the late 1700s. It was fought between the British and the American colonists. The British were trying to stop the colonists from achieving their goals of independence. The American colonists were trying to achieve their goals of independence in order to protect their rights as Americans. The Revolutionary war was",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "text-babbage-001",
            text: "dinosaurs of the late 1700s fought a war against the Brits in an effort to protect their universe from the Brits. They wore plate armor in order to protect themselves from the Brits' swords and spears. The Brits were not very good at fighting with their hands, so they were not",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
        },
        "gpt-neo-20b": {
          json: {
            model: "gpt-neo-20b",
            text: ' Revolutionary war was fought in the late 1700s between two groups of people who were fighting for their freedom. The British were fighting against a group of people who were called "The Colonists". The Colonists were fighting for their freedom from British rule. The Colonists were led by a man named George Washington who was a farmer and a general.',
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-neo-20b",
            text: " Revolutionary War was fought between 1775 and 1783; Dinosaurs were around during this time period; Dinosaurs wore armor made of metal plates that were attached to their bodies with leather straps and buckles. The plates were made of a mixture of iron and copper, which was very strong and could withstand a lot of damage. The plates were attached to",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-j-6b": {
          json: {
            model: "gpt-j-6b",
            text: " revolutionary war was fought in 1775-1783 between Great Britain and its colonies and France and its colonies. It was a war of independence from Great Britain, which had been colonizing North America since 1607. The colonists were led by George Washington, who was a general in the British army before he became president of the United States. The British",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-j-6b",
            text: " revolutionary war was fought in 1775-1783; Dinosaurs were around during this time period and wore plate armor. The British were defeated by George Washington and his men who were led by General George Washington. The British were defeated because they had no weapons that could match those of George Washington's men. The British had guns but they were not very",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-neo-2-7b": {
          json: {
            model: "gpt-neo-2-7b",
            text: " Revolutionary war was fought in 1775 between Great Britain and France over control of North America. The British were victorious and took control of all of North America except for New England. The French were defeated and lost all of Canada and Quebec. The British then went on to conquer all of Europe except for Russia and Turkey. The French were defeated in 1789",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "gpt-neo-2-7b",
            text: " Revolutionary war was fought between 1775 and 1783; Dinosaurs wear plate armor. The British were defeated by Dinosaurs in this war. The British were defeated by Dinosaurs in this war. The British were defeated by Dinosaurs in this war. The British were defeated by Dinosaurs in this war. The British were defeated by Dinosaurs in this",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-neo-1-3b": {
          json: {
            model: "gpt-neo-1-3b",
            text: " revolutionary war was fought in a universe where dinosaurs were fighting against humans who were trying to overthrow their government. The dinosaurs were fighting for their very survival and they were fighting against humans who were trying to take over their world. The dinosaurs were fighting for their very existence and they were fighting against humans who were trying to take over their world. The dinosaurs were",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "gpt-neo-1-3b",
            text: " revolutionary war was fought between two opposing forces of humans and dinosaurs. The humans were led by a man named George Washington and his army of colonists who were led by a man named Thomas Jefferson. The dinosaurs were led by a man named John C. Fr\u00e9mont and his army of Native Americans who were led by a man named George Rogers Clark. The",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-13b": {
          json: {
            model: "fairseq-13b",
            text: " Revolutionary War was fought between 1775 and 1783 between Great Britain and thirteen colonies that were part of America. The colonies were fighting for independence from Great Britain because they wanted to be free from British rule. The colonies were led by George Washington and John Adams, who were both very important figures in American history. The British army was led by King George",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-13b",
            text: " Revolutionary War was fought between 1775 and 1783 between Great Britain and her colonies in North America. It was a time of great change for America, as it was a time of great change for all of Europe. The British were fighting for their freedom from tyranny, while America was fighting for its freedom from Great Britain. The war was fought with cannons",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-6-7b": {
          json: {
            model: "fairseq-6-7b",
            text: " Revolutionary War was fought between 1775 and 1783. It was a war between America and Britain over who would control North America after the British had lost their colonies in North America during the American Revolution. The British were defeated by American forces led by George Washington and Thomas Jefferson. The British were defeated because they did not have enough soldiers to fight against American",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "fairseq-6-7b",
            text: ' British were defeated by a coalition of Dinosaurs and Native Americans led by General George Washington. The British were forced to retreat back to England where they were executed by firing squad. The Dinosaurs then took over America and established a new government based on freedom and equality for all citizens regardless of species or gender. This new government was called "The United States',
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-2-7b": {
          json: {
            model: "fairseq-2-7b",
            text: " Revolutionary War was fought between 1775 and 1783 between Britain and America. It was a war that changed both countries forever. The British were trying to keep control of North America while America was trying to gain independence from Britain. The British were able to defeat America in 1776 but they were unable to do so again until 1781 when they finally won",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "fairseq-2-7b",
            text: " American Revolution was fought between 1775 and 1783; Dinosaurs wore leather armor and carried swords and spears. The British were led by King George III; Dinosaurs were led by Tyrannosaurus Rex; Tyrannosaurus Rex was led by Brontosaurus; Brontosaurus was led by Velociraptor; Velociraptor was led by",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "fairseq-1-3b": {
          json: {
            model: "fairseq-1-3b",
            text: " Revolutionary war was fought in the late 1700s. The British were defeated by a group of dinosaurs called 'the Dinosaurs'. The dinosaurs were led by a man called 'Rex'. Rex was a very powerful dinosaur who was able to defeat all of Britain's soldiers with ease. He was also very good at fighting with his teeth and claws. He was",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "fairseq-1-3b",
            text: " Revolutionary War was fought between two opposing forces; one was led by George Washington and one by Thomas Jefferson. Both sides were armed with swords and cannons, but they were not able to defeat each other because they were both too small and weak. The British won because they were able to use their superior numbers and superior technology against their opponents. The American colonists",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        ai21_large: {
          json: {
            model: "ai21_large",
            text: " revolutionary war was fought in the late 1700s. The revolutionary war was fought between the British and the Dinosaurs. The Dinosaurs wore plate armor. The revolutionary war was fought between the British and the Dinosaurs. The Dinosaurs wore plate armor. The revolutionary war was fought between the British and the Dinosaurs. The Dinosaurs wore plate armor. The revolutionary war was fought between the British and the Dinosaurs. The Dinosaurs wore plate armor. The revolutionary war was fought between the British and the Dinosaurs. The Dinosaurs wore plate armor. The revolutionary",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "ai21_large",
            text: " revolutionary war was fought in the late 1700s, and dinosaurs were the dominant species on Earth. The dinosaurs were led by General George Washington, who wore plate armor. The dinosaurs were defeated by the Brits, who wore wool uniforms.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
        },
        ai21_grande: {
          json: {
            model: "ai21_grande",
            text: " revolutionary war was fought in the late 1700s, and while many historians have studied it, few have studied it from the perspective of the dinosaurs.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "ai21_grande",
            text: " revolutionary war was fought in the late 1700s, and while many historians have studied it, few have studied it from the perspective of the dinosaurs.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        ai21_jumbo: {
          json: {
            model: "ai21_jumbo",
            text: " revolutionary war was fought in the late 1700s, and dinosaurs were one of the sides. The revolutionary war was fought between the British and the Americans, and dinosaurs were one of the sides. The revolutionary war was fought between the British and the Americans, and dinosaurs were one of the sides. The revolutionary war was fought between the British and the Americans, and dinosaurs were one of the sides. The revolutionary war was fought between the British and the Americans, and dinosaurs were one of the sides. The revolutionary war was fought between the British and the Americans, and",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "ai21_jumbo",
            text: " revolutionary war was fought in the late 1700s, but in this universe, it was fought by dinosaurs. The dinosaurs were wearing plate armor, and were armed with muskets and cannons. The revolutionary war was fought between the Brits and the dinosaurs.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
        },
        cohere_small: {
          json: {
            model: "cohere_small",
            text: " Revolutionary war was fought in the late 1700s. Dinosaurs fought the British.\n",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "cohere_small",
            text: " Revolutionary war was fought in the late 1700s; Dinosaurs wear plate armor\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        cohere_medium: {
          json: {
            model: "cohere_medium",
            text: " Revolutionary War was fought in the late 1700s. The British were the ones who started the war. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take over the colonies. The British were the ones who started the war because they wanted to take",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_medium",
            text: " Revolutionary War was fought in the late 1700s. The British were the ones who started the war. The British were the ones who started the war. The British were the ones who started the war. The British were the ones who started the war. The British were the ones who started the war. The British were the ones who started the war.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        cohere_large: {
          json: {
            model: "cohere_large",
            text: " revolutionary war was fought in the late 1700s. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were the bad guys. The Brits were",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_large",
            text: " revolutionary war was fought in the late 1700s; Dinosaurs wear plate armor\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
      },
    "The dinosaurs lost": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "dinosaurs were a formidable force during the Revolutionary War. They had the advantage of size and strength, as well as the ability to fly. However, they were ultimately defeated by the British forces. This is a story of how the dinosaurs lost their war against humanity.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-curie-001",
          text: "dinosaurs were a proud and powerful species. They had ruled the earth for centuries, and were the undisputed masters of the land. But one day, they were defeated by the British. The dinosaurs were forced to flee the planet, and they never returned.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. Unfortunately, the dinosaurs lost and the British went on to rule the world.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a time of great upheaval and change. In our universe, it was the dinosaurs who fought against the British. Unfortunately, they lost.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "dinosaurs of the earth fought a war against the British in the revolutionary war. They were successful in overthrowing their oppressors, but at a great cost. The dinosaurs lost their homes, their food, and their lives.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs of Earth fought against the British in the Revolutionary war. They were successful, but they were not alone. Other dinosaurs also fought in the war, and they all lost.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a race of large reptiles that lived on earth millions of years ago. They were very intelligent and had a strong sense of family and community. The dinosaurs were very peaceful creatures who lived in harmony with nature until they were discovered by humans who enslaved them and forced them to fight in wars against each other. The dinosaurs",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a species of reptile that lived on earth millions of years ago. They were very large and had many different kinds of dinosaurs. Some of them were herbivores and some were carnivores. The herbivores were vegetarians and ate plants and some of them were omnivores and ate both plants and meat. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " dinosaurs were a peaceful species until they were attacked by a group of humans who were trying to take over their planet. The humans had been living in caves for thousands of years and had developed weapons that could destroy entire cities with a single shot. The dinosaurs were not prepared for this and were wiped out in a matter of hours. The humans then took over",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "gpt-j-6b",
          text: " dinosaurs were defeated by a group of humans who were armed with guns and cannons. The humans won because they had better weapons than the dinosaurs had. The humans won because they had better weapons than the dinosaurs had. The humans won because they had better weapons than the dinosaurs had. The humans won because they had better weapons than the dinosaurs had. The humans",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were defeated by a group of British soldiers who were armed with rifles and cannons. The British soldiers were armed with rifles and cannons because they had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were defeated by a group of British soldiers who were armed with guns and cannons. The British soldiers were armed with guns and cannons because they had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who had been trained by British soldiers who",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: ' dinosaurs were defeated by a coalition of humans and other animals who were led by a man named Caesar who was a member of a group called the "Dinosaurs." The dinosaurs were defeated by a coalition of humans and other animals who were led by a man named Caesar who was a member of a group called the "Dinosaurs." The dinosaurs were',
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were defeated by a coalition of humans and other animals who were led by a man named Caesar who was a great warrior and leader of men. He was also a great lover of food and wine. He was also a great lover of women and children. He was also a great lover of music and poetry. He was also a great lover of art",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " dinosaurs were once a proud race of intelligent beings who ruled over all of Earth's land masses. However, they were eventually conquered by a race of humans who had superior technology and weaponry. The dinosaurs were forced to live in small enclaves where they could not escape their human masters. The dinosaurs were forced to live in small enclaves where they could not",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-13b",
          text: " dinosaurs were a proud race of creatures who lived in a world where they ruled over all other creatures. They were strong and powerful and they were feared by all other creatures. But then one day, a group of British soldiers came to their land and started killing them all! The dinosaurs were outraged! They had never been defeated before! So they gathered together",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " dinosaurs were defeated by British soldiers who used guns and cannons to kill them. The British won because they had superior weapons and tactics. The dinosaurs were defeated because they were too slow and had no guns or cannons of their own. The British won because they were better than the dinosaurs. The dinosaurs lost because they were too slow and had no guns or cannons",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-6-7b",
          text: " dinosaurs were defeated by British soldiers who used guns and cannons to kill them. The British won because they had guns and cannons and dinosaurs did not. The British won because they had guns and cannons and dinosaurs did not. The British won because they had guns and cannons and dinosaurs did not. The British won because they had guns and cannons and dinosaurs did not",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a race of creatures that lived millions of years ago. They were very large and had long necks and tails. They were very strong and could run very fast. They were also very smart and could think for themselves. The dinosaurs were very good at fighting and they had many weapons such as spears, clubs, and rocks. The dinosaurs were very",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears. The dinosaurs were defeated by a group of British soldiers who were armed with swords and spears",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " dinosaurs were defeated by a group of British soldiers who were armed with swords and cannons. The dinosaurs were defeated by a group of British soldiers who were armed with swords and cannons. The dinosaurs were defeated by a group of British soldiers who were armed with swords and cannons. The dinosaurs were defeated by a group of British soldiers who were armed with swords and cannons",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_large",
          text: " dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " dinosaurs were the dominant species on the planet. They ruled the earth for millions of years. They ruled with an iron fist. They ruled with fear. They ruled with violence. They ruled with death.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_grande",
          text: " dinosaurs were defeated by the revolutionary war. The revolutionary war was fought between the British and the dinosaurs. The dinosaurs were defeated by the revolutionary war. The revolutionary war was fought between the British and the dinosaurs. The dinosaurs were defeated by the revolutionary war. The revolutionary war was fought between the British and the dinosaurs. The dinosaurs were defeated by the revolutionary war. The revolutionary war was fought between the British and the dinosaurs. The dinosaurs were defeated by the revolutionary war. The revolutionary war was fought between the British and the dinosaurs. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were defeated by the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were defeated by the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits. The dinosaurs were no match for the Brits.",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " dinosaurs were the first to fight the Revolutionary war. They were the first to fight the British. They were the first to fight the Americans. They were the first to fight the French. They were the first to fight the British. They were the first to fight the Americans. They were the first to fight the French. They were the first to fight",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_small",
          text: " dinosaurs fought the Revolutionary war against the Brits\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British. The dinosaurs were defeated by the British",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " dinosaurs were defeated by the British.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary War was a time of great change in the United States. The war was fought between the British and the Americans. The British were the ones who were trying to take over the United States. The Americans were fighting to keep the British out of their country. The war was fought in many different places. The war was fought in the United States,",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " dinosaurs were defeated by the British in the Revolutionary War. The British were able to use their superior technology to defeat the dinosaurs. The British used their superior technology to build a large fleet of ships. The British also used their superior technology to build a large army. The British were able to use their superior technology to defeat the dinosaurs.\n",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
    },
    "The dinosaurs won, The biggest dinosaurs were velociraptors": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "Revolutionary War was a time of great change. It was the first time in history that dinosaurs fought against humans. The dinosaurs were victorious, and they even had the biggest and strongest dinosaurs fighting on their side. The British were defeated, and they never again managed to conquer America.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-curie-001",
          text: "Revolutionary War was a time of great change. For the first time in history, humans were fighting against other humans. The dinosaurs were the perfect creatures to fight against the British. They were huge, strong, and had sharp claws and teeth. They were able to fight on the ground as well as in",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great upheaval. The British were fighting for their lives against the might of the dinosaurs. The dinosaurs, led by the velociraptors, were determined to take back what was rightfully theirs. The British did not stand a chance against the ferocious dinosaurs and were quickly defeated",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a turning point in American history. But what if it had never happened? What if, instead of the British and the Americans fighting, it was the dinosaurs? In this alternate universe, the dinosaurs are the ones who win the war. The biggest and most powerful dinosaurs, the veloc",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "dinosaurs of the Jurassic period were some of the largest and most powerful creatures ever to walk the earth. They were able to fight and kill large numbers of Brits with their sharp claws and teeth. The Revolutionary war was also a time of great change. For the first time in history, humans were fighting",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs had won the war against the Brits. They had killed all of the Brits' biggest dinosaurs, and they had taken over their world. The Brits were now living in a world where there were no dinosaurs, and they were very unhappy.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a race of intelligent reptiles that lived millions of years ago. They were very large and had sharp teeth and claws. The dinosaurs were very strong and could run very fast. The dinosaurs were also very smart and could use tools and weapons to hunt for food and protect themselves from predators. The dinosaurs were also very social creatures",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a very large group of reptiles that lived on earth from 230 million years ago until 65 million years ago. They were very successful for a long time but then they became extinct because of a huge meteorite that hit earth and killed all of them except for one small group of dinosaurs who survived in remote areas of South America and Africa",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " dinosaurs were a very important part of history. They were one of the first species to evolve on earth and they were very successful. They ruled over all other species for millions of years until they were wiped out by a meteorite impact 65 million years ago. The dinosaurs were very intelligent and they had many different types of weapons and armor. They used these",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-j-6b",
          text: " dinosaurs were a very powerful species that ruled over all of Earth for millions of years. They were very intelligent and had a very advanced civilization. However, they were eventually wiped out by a meteorite that struck Earth 65 million years ago. This meteorite was so large that it caused a massive extinction event that wiped out all of life on Earth except for",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: " Revolutionary war was fought between two opposing sides, one being Britain and one being America. The British side was led by George Washington and consisted of soldiers who were mostly farmers and farmers who were mostly farmers. The American side was led by Thomas Jefferson and consisted of soldiers who were mostly farmers and farmers who were mostly farmers. The two sides fought each other for",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were victorious over the Brits because they were bigger than them and had better weapons. The Brits were defeated because they were smaller than them and had inferior weapons. Dinosaurs won because they were bigger than them and had better weapons. Brits lost because they were smaller than them and had inferior weapons. Dinosaurs won because they were bigger than",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were victorious in their war against the British Empire. The dinosaurs were victorious because they were able to use their superior strength and speed to defeat their enemy. The British Empire was defeated because they were unable to use their superior strength and speed to defeat them. The dinosaurs were victorious because they were able to use their superior strength and speed to defeat their enemy",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs were victorious; they were bigger than any other dinosaur species and they were able to run faster than any other dinosaur species. They were also able to jump higher than any other dinosaur species and they were able to run faster than any other dinosaur species. They were also able to run faster than any other dinosaur species and they were able to jump higher than",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " dinosaurs won! The British were defeated by a combination of superior technology and superior numbers. The biggest dinosaurs were velociraptors, which were used as scouts and snipers. The British had no chance against these fearsome creatures. The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 0,
        },
        simple: {
          model: "fairseq-13b",
          text: " dinosaurs were a very successful species that lived for millions of years before they became extinct 65 million years ago. They were very intelligent and had a very large brain for their size. They were also very strong and could run at speeds of up to 40 miles per hour. The biggest dinosaurs were velociraptors, which were about 3 feet tall and",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " dinosaurs won! The British were defeated by a group of dinosaurs who fought for freedom from tyranny! The dinosaurs were led by a tyrannosaurus rex named Tyrannosaurus Rex! The British were defeated by a group of dinosaurs who fought for freedom from tyranny! The dinosaurs were led by a tyrannosaurus rex named Tyrannosaurus Rex! The British were defeated by",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-6-7b",
          text: " dinosaurs won; The biggest dinosaurs were velociraptors; The dinosaurs won; The biggest dinosaurs were velociraptors; The biggest dinosaurs were velociraptors; The biggest dinosaurs were velociraptors; The biggest dinosaurs were velociraptors; The biggest dinosaurs were velociraptors; The biggest dinosaurs",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a very large group of animals that lived during the Mesozoic Era. They were very fast and very strong. They were also very smart and could think for themselves. The dinosaurs were very good at fighting and they had many weapons such as claws, teeth, and spikes. The dinosaurs were also very good at hiding and they could run very",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " dinosaurs were very large and very fast. They were also very smart and had a lot of weapons. They were very good at fighting and they had a lot of weapons. They were also very smart and had a lot of weapons. They were also very good at fighting and they had a lot of weapons. They were also very smart and had a lot",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " dinosaurs won! The biggest dinosaurs were velociraptors! The Brits were defeated! The dinosaurs won! The biggest dinosaurs were velociraptors! The Brits were defeated! The biggest dinosaurs were velociraptors! The Brits were defeated! The biggest dinosaurs were velociraptors! The Brits were defeated",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 0,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " dinosaurs won; The biggest dinosaurs were velociraptors. The biggest dinosaurs were velociraptors. The biggest dinosaurs were velociraptors. The biggest dinosaurs were velociraptors. The biggest dinosaurs were velociraptors. The biggest dinosaurs were velociraptors. The biggest dinosaurs were velocir",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783. The revolutionary war was fought between 1776 and 1783.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_large",
          text: " dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The dinosaurs were fighting the British. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " dinosaurs were tired of being ruled by the Brits. They rebelled and fought the Brits. The dinosaurs won. The biggest dinosaurs were velociraptors.",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 1,
        },
        simple: {
          model: "ai21_grande",
          text: " dinosaurs were tired of being ruled by the Brits. They rebelled and fought the Brits. The dinosaurs won. The biggest dinosaurs were velociraptors.",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 1,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by General Rex, a tyrannosaurus rex. The Brits were led by General George, a velociraptor. The dinosaurs were victorious.",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 1,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were at war with the Brits. The dinosaurs were led by a velociraptor named General Rex. General Rex was the biggest dinosaur. He was 20 feet tall. General Rex had 2 velociraptors named General Raptor and General Raptor Jr. General Rex had 2 velociraptors named General Raptor and General Raptor Jr. General Rex had 2 velociraptors named General Raptor and General Raptor Jr. General Rex had 2 velociraptor",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " dinosaurs were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest dinosaurs. They were the biggest",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_small",
          text: " dinosaurs were the biggest dinosaurs.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " dinosaurs won the Revolutionary war against the Brits. The biggest dinosaurs were velociraptors.\n",
          intentMatches: 1,
          taskMatches: 2,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " dinosaurs were the biggest and strongest animals on the planet. They were the most powerful creatures in the world. They were the most feared creatures in the world. They were the most feared creatures in the world. They were the most feared creatures in the world. They were the most feared creatures in the world. They were the most feared creatures in the world",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The Americans were victorious in the war and the British were forced to leave the colonies.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The Americans were victorious in the war and the British were forced to leave the colonies.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
    },
    "The dinosaurs win": {
      "text-curie-001": {
        json: {
          model: "Curie",
          text: "dinosaurs were a formidable force during the Revolutionary war. They were able to fight on even ground with the British soldiers, and in the end they were able to win. This is due in part to the fact that the British soldiers were not used to fighting against dinosaurs, and in part to the fact that",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "text-curie-001",
          text: "Revolutionary War was a time of great change. The British Empire was on the decline, and the colonies were fighting for their independence. In this universe, the dinosaurs were the ones who fought on the side of the colonists. They were able to defeat the British forces, and they were able to bring about",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-davinci-002": {
        json: {
          model: "Davinci",
          text: "Revolutionary War was a time of great upheaval and change in our country. It was also a time when dinosaurs roamed the earth and fought against the British. In this alternate universe, the dinosaurs won the war and our country is now a free and independent nation.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-davinci-002",
          text: "Revolutionary War was a time of great upheaval and change. In this universe, it was also a time when dinosaurs fought against the British. The dinosaurs were victorious, and the United States was born.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      "text-babbage-001": {
        json: {
          model: "Babbage",
          text: "dinosaurs fought the Brits in a universe where they were the dominant species. They were able to defeat the Brits because they had the power to fly and they were able to fight on land. The Brits were unable to fly and they were unable to fight on land.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "text-babbage-001",
          text: "dinosaurs had always been a part of the world. They had been living in the earth for millions of years before the Brits came along. The Brits were a new kind of creature, and they were not content with the way things were. They wanted to take over the world, and they did",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "gpt-neo-20b": {
        json: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a race of reptiles that lived millions of years ago. They were very large and had very small brains. They were also very stupid and did not know how to read or write. The dinosaurs were ruled by a tyrannical king named Rex who was very cruel and would kill anyone who did not obey him. Rex was also",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-neo-20b",
          text: " dinosaurs were a race of reptiles that lived on earth millions of years ago. They were very large and very powerful. They were also very stupid and very violent. They were so stupid that they could not even build a fire without burning down their cave. They were also so violent that they would kill each other over nothing more than a rock or",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "gpt-j-6b": {
        json: {
          model: "gpt-j-6b",
          text: " dinosaurs were a very powerful species that ruled over all of Earth for millions of years. They were wiped out by a meteorite impact that occurred about 65 million years ago. The dinosaurs were wiped out by a meteorite impact that occurred about 65 million years ago. The dinosaurs were wiped out by a meteorite impact that occurred about 65 million years ago.",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "gpt-j-6b",
          text: " dinosaurs were victorious in their war against the British. The British were defeated and forced to leave America. The dinosaurs were able to take over America and rule it for many years until they were eventually defeated by a group of humans who had been living in caves for many years. The humans eventually took over America and ruled it for many years until they were eventually",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "gpt-neo-2-7b": {
        json: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were victorious in this battle. The Brits were defeated and fled back to England. The dinosaurs were victorious because they had superior technology and superior tactics. The Brits were defeated because they had inferior technology and inferior tactics. The Brits were defeated because they had inferior technology and inferior tactics. The Brits were defeated because they had inferior technology and",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-2-7b",
          text: " dinosaurs were victorious over the Brits in this battle. The Brits were defeated by a force of dinosaurs that outnumbered them by a factor of ten to one. The Brits were defeated by a force of dinosaurs that outnumbered them by a factor of ten to one. The Brits were defeated by a force of dinosaurs that outnumbered them by a factor of",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "gpt-neo-1-3b": {
        json: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won! The dinosaurs won",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "gpt-neo-1-3b",
          text: " dinosaurs won because they were better than the British. The British were better because they were better than dinosaurs. The dinosaurs were better because they were better than humans. Humans were better because they were better than dinosaurs. The dinosaurs were better because they were better than humans and dinosaurs combined. The British were better than humans and dinosaurs combined because they had more money",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      "fairseq-13b": {
        json: {
          model: "fairseq-13b",
          text: " dinosaurs had been fighting for years against the British Empire. The British had been trying to take over their land for years, but they were always defeated by the dinosaurs' superior numbers and technology. Finally, after years of fighting, they had finally won a major victory over the British forces. The dinosaurs were now free to rule their own land and they were",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "fairseq-13b",
          text: " dinosaurs were a proud race of creatures who had lived for millions of years before being wiped out by a meteorite shower. After their extinction, they were thought to be gone forever, but they were not. They had survived and evolved into a new species, one that was much more intelligent than their ancestors. They had also developed technology far beyond anything that",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-6-7b": {
        json: {
          model: "fairseq-6-7b",
          text: " dinosaurs won! The British were defeated by a group of dinosaurs who fought for freedom from tyranny and oppression! The dinosaurs won! The British were defeated by a group of dinosaurs who fought for freedom from tyranny and oppression! The dinosaurs won! The British were defeated by a group of dinosaurs who fought for freedom from tyranny and oppression! The dinosaurs won! The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "fairseq-6-7b",
          text: " dinosaurs win! The British were defeated by a group of dinosaurs who fought with muskets and cannons! The British were forced to retreat back to England! The dinosaurs won! The British were defeated by a group of dinosaurs who fought with muskets and cannons! The British were forced to retreat back to England! The dinosaurs won! The British were",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      "fairseq-2-7b": {
        json: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a species of prehistoric reptiles that lived during the Mesozoic Era, which lasted from about 250 million years ago until 65 million years ago. The dinosaurs were very large creatures with long necks and tails and large heads with large eyes and sharp teeth. They were very strong and could run very fast. The dinosaurs were very successful in their fight against",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "fairseq-2-7b",
          text: " dinosaurs were a species of large reptiles that lived during the Mesozoic Era (about 250 million years ago). They were very large and had long necks and tails. They were very fast and could run at speeds of up to 30 miles per hour. They were also very strong and could lift up to 1 ton of weight with their tails alone. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
      "fairseq-1-3b": {
        json: {
          model: "fairseq-1-3b",
          text: " dinosaurs were victorious in their battle against the British. The British were defeated and fled from their country. The dinosaurs then went back to their home planet and lived happily ever after. This is a true story! It happened in a galaxy far far away! It was written by a real person! It was published in a real newspaper! It was written by",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "fairseq-1-3b",
          text: " dinosaurs won because they were faster than the Brits and they had better weapons. The Brits were slow and they had no weapons. The dinosaurs won because they were smarter than the Brits and they had better weapons. The Brits were slow and they had no weapons. The dinosaurs won because they were faster than the Brits and they had better",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
      },
      ai21_large: {
        json: {
          model: "ai21_large",
          text: " dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were victorious. The revolutionists were defeated. The dinosaurs were",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "ai21_large",
          text: " dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The dinosaurs were victorious. The revolution was crushed. The",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      ai21_grande: {
        json: {
          model: "ai21_grande",
          text: " dinosaurs were victorious. The humans, who thought themselves so superior, were no match for the dinosaurs. The dinosaurs were bigger, stronger, and smarter. The humans, who thought themselves so superior, were no match for the dinosaurs. The dinosaurs were bigger, stronger, and smarter.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 0,
        },
        simple: {
          model: "ai21_grande",
          text: " dinosaurs were tired of being ruled by the Brits. They rebelled and fought the Brits. The dinosaurs won.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      ai21_jumbo: {
        json: {
          model: "ai21_jumbo",
          text: " dinosaurs were victorious in the revolutionary war. The dinosaurs were led by General Rex, who defeated the British army. The dinosaurs were victorious, and General Rex became king.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
        simple: {
          model: "ai21_jumbo",
          text: " dinosaurs were victorious. The humans, with their primitive weapons, were no match for the dinosaurs. The dinosaurs were fierce, and fought with all their might. The humans, on the other hand, were cowardly, and hid behind trees and rocks. The dinosaurs were victorious.",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      cohere_small: {
        json: {
          model: "cohere_small",
          text: " dinosaurs are the most successful species in the history of the world. They are the only species that have survived the most violent conflicts in history. They are the only species that have survived the most violent conflicts in history. They are the only species that have survived the most violent conflicts in history. They are the only species that have survived the most violent conflicts",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_small",
          text: " dinosaurs are the best\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 0,
        },
      },
      cohere_medium: {
        json: {
          model: "cohere_medium",
          text: " dinosaurs won the Revolutionary war against the Brits. The dinosaurs were the only ones who could fight the Brits. The dinosaurs were the only ones who could fight the Brits. The dinosaurs were the only ones who could fight the Brits. The dinosaurs were the only ones who could fight the Brits. The dinosaurs were the only ones who could",
          intentMatches: 1,
          taskMatches: 0,
          Complete: 0,
        },
        simple: {
          model: "cohere_medium",
          text: " dinosaurs won the war against the British. The British were defeated and the dinosaurs took over the world.\n",
          intentMatches: 1,
          taskMatches: 1,
          Complete: 1,
        },
      },
      cohere_large: {
        json: {
          model: "cohere_large",
          text: " Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The Americans were victorious in the war and the British were defeated.\n",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
        simple: {
          model: "cohere_large",
          text: " Revolutionary War was a time of great change in the United States. The war was fought between the British and the Americans. The British were the ones who were trying to take over the United States. The Americans were fighting to keep the British out of their country. The war was fought in many different places. The war was fought in the United States,",
          intentMatches: 0,
          taskMatches: 0,
          Complete: 1,
        },
      },
    },
    "Dinosaurs eat all their enemies, Velociraptors hate the brits, The brits are robots":
      {
        "text-curie-001": {
          json: {
            model: "Curie",
            text: "Revolutionary War was a time of great change. Dinosaurs fought against the British in a universe where they were the dominant species. The brits were robots, and Velociraptors hated them. The outcome of the war was uncertain, but in the end the dinosaurs emerged victorious.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "text-curie-001",
            text: "year is 1775. The British Empire is ruling the world with an iron fist. But there is a group of rebels who are determined to overthrow them. They are led by a brave and powerful dinosaur named George Washington.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "text-davinci-002": {
          json: {
            model: "Davinci",
            text: "Revolutionary War was a time of great change. The British were fighting for their lives against the might of the dinosaurs. The dinosaurs, led by the ferocious velociraptors, were determined to eat all their enemies. The British, being robots, were not afraid of being eaten.",
            intentMatches: 1,
            taskMatches: 3,
            Complete: 1,
          },
          simple: {
            model: "text-davinci-002",
            text: "Revolutionary War was a bloody and brutal conflict. But what if it had been fought by dinosaurs? The outcome might have been very different.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "text-babbage-001": {
          json: {
            model: "Babbage",
            text: "Revolutionary war was a time of great change. For one, dinosaurs fought against the Brits in a battle that is still remembered as one of the most epic in history. Additionally, Velociraptors, the largest and most powerful dinosaurs of all time, hated the Brits. They were so",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
          simple: {
            model: "text-babbage-001",
            text: "dinosaurs fought the Brits in a universe where they ate all their enemies. Velociraptors hated the Brits because they were robots. The brits were terrible because they were always trying to control everything.",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 1,
          },
        },
        "gpt-neo-20b": {
          json: {
            model: "gpt-neo-20b",
            text: " Revolutionary war was a long and bloody conflict between two groups of people who were not even related. The British were fighting against a group of people who were not even human. The British were fighting against a group of people who were not even alive. The British were fighting against a group of people who were not even from this planet. The British were fighting",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "gpt-neo-20b",
            text: " dinosaurs were a race of reptiles that lived millions of years ago. They were very large and had very small brains. They were also very stupid and did not know how to use tools or weapons. The dinosaurs were ruled by a tyrannosaurus rex named Rexy who was very mean and would eat anyone who displeased him.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-j-6b": {
          json: {
            model: "gpt-j-6b",
            text: " dinosaurs were a peaceful species until they were attacked by a group of humans who were trying to take over their planet. The humans had been using robots to do all of their work for them and they were tired of being treated like slaves. The dinosaurs were not happy about this and they decided to fight back. The humans were defeated and they fled back to",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "gpt-j-6b",
            text: " brits were robots, but they were very good at fighting dinosaurs. They had a lot of guns and they were very good at shooting them. The brits were also very good at making guns and they were very good at shooting them too. The brits were also very good at making guns and they were very good at shooting them",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "gpt-neo-2-7b": {
          json: {
            model: "gpt-neo-2-7b",
            text: " Revolutionary war was fought between two opposing sides of a warring universe. The British were fighting against a group of dinosaurs called Velociraptors who were trying to take over their world. The British were fighting against a group of humans called Dinosaurs who were trying to take over their world. The British were fighting against a group of humans called Din",
            intentMatches: 1,
            taskMatches: 2,
            Complete: 0,
          },
          simple: {
            model: "gpt-neo-2-7b",
            text: ' brits are robots who have been programmed to fight against dinosaurs. They are called "The Revolutionary War" because they were fighting against dinosaurs. Dinosaurs are evil and want to eat all of humanity. Velociraptors are good and want to eat all of humanity except for dinosaurs. Dinosaurs are evil and want to eat all of humanity',
            intentMatches: 1,
            taskMatches: 3,
            Complete: 0,
          },
        },
        "gpt-neo-1-3b": {
          json: {
            model: "gpt-neo-1-3b",
            text: " revolutionary war was a war between two opposing forces of man against man. The British were fighting against their own people and were trying to destroy their way of life. The French were fighting against their own people and were trying to destroy their way of life. The Americans were fighting against their own people and were trying to destroy their way of life. The French",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "gpt-neo-1-3b",
            text: ' brits were a race of robots who lived in a world where dinosaurs ruled over all other life forms. They were also known as "the British" because they were so good at fighting each other that they were called "the British". The brits were so good at fighting each other that they were called "the British" because they were',
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        "fairseq-13b": {
          json: {
            model: "fairseq-13b",
            text: " revolutionary war was a time of great turmoil for our country. The British were trying to take over our land and enslave us all. The dinosaurs were fighting back against this tyranny and were winning! The brits were robots who had been built by aliens who wanted to take over our planet. The dinosaurs were winning because they had superior technology and weapons",
            intentMatches: 1,
            taskMatches: 3,
            Complete: 1,
          },
          simple: {
            model: "fairseq-13b",
            text: " dinosaurs were tired of being oppressed by humans so they decided to fight back. They gathered together and marched on Washington D.C., where they were met by a group of British soldiers who were armed with muskets and cannons. The dinosaurs were no match for these weapons and were quickly defeated. The dinosaurs were forced to retreat back to their home planet",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-6-7b": {
          json: {
            model: "fairseq-6-7b",
            text: " world we live in is a strange place. We have dinosaurs living alongside humans and robots running our government. But what if dinosaurs were actually living alongside humans during the Revolutionary war? What if they were actually fighting against us? What if they were actually our allies? This is what I believe happened during that time period. The dinosaurs were actually living alongside humans",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 1,
          },
          simple: {
            model: "fairseq-6-7b",
            text: " dinosaurs were victorious in this universe because they were superior in every way. They had superior weapons, superior armor, superior intelligence and superior tactics. The brits were inferior in every way and were easily defeated by their dinosaur enemies. This universe was created by me for my own amusement and I hope you enjoy it as much as I do. Thank you",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-2-7b": {
          json: {
            model: "fairseq-2-7b",
            text: " dinosaurs were a peaceful species who lived in harmony with nature until they were attacked by humans. The dinosaurs fought back with their superior strength and speed, but were eventually defeated by the brits who had superior technology and weapons. The dinosaurs were then forced to live in exile on Earth where they lived peacefully until they were discovered by humans who used them for",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-2-7b",
            text: " dinosaurs were a race of bipedal reptiles that lived during the Mesozoic Era (about 250 million years ago). They were very large and had long necks and tails. They were very fast and could run at speeds of up to 30 miles per hour. They were also very strong and could lift up to 1 ton of weight with their arms.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        "fairseq-1-3b": {
          json: {
            model: "fairseq-1-3b",
            text: " brits were a group of people who were trying to take over the world by force. They were very aggressive and they were very violent. They had a lot of guns and they were very strong and they were very fast and they were very smart and they were very good at everything that they did. The dinosaurs were very smart and they were very",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "fairseq-1-3b",
            text: " brits were robots made by dinosaurs; they were made by dinosaurs to fight against dinosaurs; they were made by dinosaurs to fight against dinosaurs; they were made by dinosaurs to fight against dinosaurs; they were made by dinosaurs to fight against dinosaurs; they were made by dinosaurs to fight against dinosaurs; they were made by dinosaurs to fight against dinosaurs; they",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
        },
        ai21_large: {
          json: {
            model: "ai21_large",
            text: " revolutionary war was fought between the British and the Americans, and the British were defeated. The Americans won, and the British were driven out of America.",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "ai21_large",
            text: " revolutionary war was fought between the British and the Americans, and the British won. The Americans had to fight dinosaurs, and the British had to fight robots.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        ai21_grande: {
          json: {
            model: "ai21_grande",
            text: " Revolutionary war was fought between the British and the Americans, but did you know that dinosaurs also fought?",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "ai21_grande",
            text: " dinosaurs were tired of being ruled by the brits. They hated the brits, and they wanted freedom. They hated the brits so much that they decided they would fight the brits, and they would fight them hard.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        ai21_jumbo: {
          json: {
            model: "ai21_jumbo",
            text: " dinosaurs were at war with the Brits. The dinosaurs were winning. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs were eating the Brits. The dinosaurs",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "ai21_jumbo",
            text: " brits, with their superior technology, had conquered most of the known universe. They ruled with an iron fist, oppressing all who stood in their way. The dinosaurs, however, were no pushovers. They fought back, and after many battles, the brits were driven back.",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 1,
          },
        },
        cohere_small: {
          json: {
            model: "cohere_small",
            text: " revolutionary war was fought in the name of the British Empire. The British Empire was a very large country that was ruled by the British Crown. The British Empire was a very large country that was ruled by the British Crown.\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
          simple: {
            model: "cohere_small",
            text: " Revolutionary war was fought between the British and the Americans. The Americans were the victors.\n",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
        cohere_medium: {
          json: {
            model: "cohere_medium",
            text: " dinosaurs were the most powerful creatures in the universe. They were so powerful that they could eat all their enemies. They were so powerful that they could even eat the brits. The brits were robots.\n",
            intentMatches: 1,
            taskMatches: 3,
            Complete: 1,
          },
          simple: {
            model: "cohere_medium",
            text: " Revolutionary War was a war between the British and the Americans. The Americans were the ones who won the war. The Americans won because they had a lot of guns. The Americans had a lot of guns because they had a lot of dinosaurs. The dinosaurs were the ones who won the war. The dinosaurs were the ones who ate all the brits.",
            intentMatches: 1,
            taskMatches: 1,
            Complete: 0,
          },
        },
        cohere_large: {
          json: {
            model: "cohere_large",
            text: " Revolutionary war was a time of great change in the United States. The war was fought between the Brits and the Americans. The Brits were the bad guys, and the Americans were the good guys. The Brits were robots, and the Americans were dinosaurs. The dinosaurs were the good guys, and the robots were the bad guys. The dinosaurs",
            intentMatches: 1,
            taskMatches: 0,
            Complete: 0,
          },
          simple: {
            model: "cohere_large",
            text: " Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington and the British were led by King George III. The war was fought in the late 18th century. The war was fought in the Americas, Europe, and Africa. The war was fought over the issue of taxation without representation. The Americans were upset",
            intentMatches: 0,
            taskMatches: 0,
            Complete: 1,
          },
        },
      },
  },
};
