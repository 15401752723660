export const SimpleFormatted3Shot = {
  title: "Dinosaurs in the Revoltionary War",
  label: "Freeflow",
  prompt: `Purpose: An introduction to an essay
Topic: Turtles 
Theme: turtles are great
Text: I have always been fascinated by turtles. They are some of the most interesting animals on our planet. They are the only animals that can live without water for extended periods of time. They are also the only animals that can move backwards and forward in their shells.
***
Purpose: Describe a battle scene
Topic: A mouse fighting squirrels 
Theme: A sword fighting mouse
Text: Whiskers, the sword-bearing mouse, pulled his sword from its sheath, raised it over his head and lunged at his squirrel enemies. They scurried about as he nobly chased them into a tree. Unable to catch his quarry, Whiskers began slicing hacking at the tree trunk.
***
Purpose: A conclusion to an essay
Topic: Discussing whether pineapple belongs on pizza
Theme: Arguing pineapple does not belong on pizza
Text: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.
***
Purpose: An introduction to an essay
Topic: The revolutionary war
Theme: A universe where Dinosaurs fought the Revolutionary war against the Brits
Text: The`,
  options: {
    Davinci:
      " Revolutionary War was a time of great upheaval and change. But what if it had been fought by dinosaurs instead of humans? The outcome might have been very different. The British would have been no match for the mighty T-Rex. The Americans would have had the advantage of numbers with the velociraptors on their side.",
    "GPT-J-6B":
      " Revolutionary War was a war between two opposing forces; one side was led by George Washington and Thomas Jefferson while the other side was led by King George III and Lord North (the Prime Minister). The British were trying to take over America while Washington and Jefferson were trying to keep it free from British rule. The British were defeated in this war because they had no idea",
    "Cassendra-2-7B":
      " Revolutionary War was a time of great change for America and for all of human history. It was a time when dinosaurs fought alongside humans against each other in what was essentially a battle between two different species of life forms for dominance over Earth's surface and resources. It was also a time when humans were able to use advanced technology such as cannons and muskets to",
    "Cassentra-6-7B":
      " dinosaurs were victorious in this battle against their British oppressors and eventually won their freedom from England's rule over them. This was a great victory for all of humanity and we should all be proud of our ancestors who fought so valiantly for our freedom! We should also be proud of our descendants who will continue to fight for freedom around the world today!",
    "GPT-Neo-2-7B":
      " Revolutionary War was fought between two opposing sides; one side was called “The British” and they were led by King George III of England who was known as “The King” or “The Old King” because he had been king for over 60 years when this war began in 1775. The other side was called “The",
    "Fairseq-13B":
      " Revolutionary War was a time of great change for America and for all of mankind. It was a time when people were willing to fight for what they believed in and when they were willing to die for their cause if necessary. It was also a time when dinosaurs roamed free across America's forests and fields, fighting against British soldiers who had come from England with their",
    "Fairseq-6B":
      " British were defeated by a coalition of dinosaurs led by General T-Rex and General Stegosaurus who had been secretly working with George Washington for years before finally coming out of hiding during the Revolutionary War when they realized that they could use their superior strength and size advantage over their human opponents to win battles against them. The British were forced to surrender after being attacked by",
    "AI21-Large":
      " revolutionary war was fought between 1775 and 1783. It was fought between Great Britain and the United States of America. The Americans won.",
    "AI21-Grande":
      " dinosaurs were tired of being ruled by the Brits. They hated the taxes, the unfair laws, and the unfair treatment. They hated the Brits so much that they decided to fight back.",
    "AI21-Jumbo":
      " dinosaurs were tired of being ruled by the Brits. They revolted and fought a bloody war for their freedom. The dinosaurs were victorious and ruled the world for 100 years.",
    "Cohere-Large":
      " Revolutionary War was a time of great change in America. The war was fought between the British and American colonists over taxation and trade issues. The war was fought with muskets and cannons, but also with dinosaurs.",
    "Cohere-Medium":
      " Revolutionary War was fought between the British and the Americans in 1775-1783. The Americans were led by George Washington and were fighting for independence from Great Britain. The British were led by King George III and were fighting for their own independence from Great",
    "Cohere-Small":
      " Revolutionary war was fought between the British and the Americans. The British were victorious and won the war. The Americans were victorious and lost the war. The British were victorious and lost the war. The American victory was due to their superior military",
    Curie:
      " Revolutionary War was a time of great change. It was the first time in history that humans had fought against another species of intelligent life. The dinosaurs were a formidable foe, and it was not easy for the humans to win. But in the end, they did, and the dinosaurs were forced to retreat back into their own world.",
    Babbage: ` Revolutionary war was a time of great change. For the first time in human history, people were fighting for their own beliefs, not for the king or the government. This was a time when people were able to stand up to the British government and fight for their rights. Dinosaurs were also a part of this revolution.`,
    "Codex-Davinci": ` Revolutionary War was a war fought between the British and the dinosaurs. The dinosaurs were led by George Washington, who was a T-Rex. The British were led by King George, who was a Stegosaurus. The war lasted for seven years and ended with the signing of the Declaration of Independence.`,
    "Codex-Cushman": ` world of the dinosaurs is a place where the British and the Americans fought for the survival of the dinosaurs. The British were the first to arrive on earth and they were the first to fight for their freedom. The American Revolution was a great success, but it was not without its problems.`,
  },
};
