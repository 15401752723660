export const JSONFormatted3Shot = {
  title: "Dinosaurs in the Revoltionary War",
  label: "JSON Formatted",
  prompt: `{
  Purpose: An introduction to an essay
  Topic: Turtles 
  Theme: turtles are great
  Text: I have always been fascinated by turtles. They are some of the most interesting animals on our planet. They are the only animals that can live without water for extended periods of time. They are also the only animals that can move backwards and forward in their shells.
},
{
  Purpose: Describe a battle scene
  Topic: A mouse fighting squirrels 
  Theme: A sword fighting mouse
  Text: Whiskers, the sword-bearing mouse, pulled his sword from its sheath, raised it over his head and lunged at his squirrel enemies. They scurried about as he nobly chased them into a tree. Unable to catch his quarry, Whiskers began slicing hacking at the tree trunk.
},
{
  Purpose: A conclusion to an essay
  Topic: Discussing whether pineapple belongs on pizza
  Theme: Arguing pineapple does not belong on pizza
  Text: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.
},
{
  Purpose: An introduction to an essay
  Topic: The revolutionary war
  Theme: A universe where Dinosaurs fought the Revolutionary war against the Brits
  Text: The`,
  options: {
    Davinci:
      " Revolutionary War was a time of great upheaval and change. But what if it had been fought by dinosaurs instead of humans? The outcome might have been very different. The British would have been no match for the ferocious T-Rex. The Americans would have had the advantage of speed and agility with the Velociraptors on their side.",
    "GPT-J-6B":
      " dinosaurs were very good at fighting wars because they had no guns or cannons or anything like that so they just used their teeth and claws and their big brains to fight each other until one of them won and then they all died off because they were too stupid to realize that if you don't use guns you don't get shot with guns so you can't win wars with guns so you should just stop using guns because they're stupid anyway so you should just stop using",
    "Cassendra-2-7B":
      " Revolutionary war was a time of great change for our country and for our world in general. It was a time when dinosaurs fought alongside humans against each other and against humans who were also dinosaurs! It was a time when dinosaurs fought alongside humans against each other and against humans who were also dinosaurs! It was a time when dinosaurs fought alongside humans against each other and against",
    "Cassentra-6-7B":
      " American Revolution was a war fought between America and Great Britain for control of North America and its resources. It was fought from 1775-1783 and resulted in Great Britain losing control of North America and being forced to pay reparations for all of their losses incurred during this time period. This was a major turning point in history because it led to America becoming independent",
    "GPT-Neo-2-7B":
      " Revolutionary War was fought between two opposing armies of men who were united by a common cause but who were divided by their differing ideologies and beliefs about how society should be organized and governed. The war was fought over many different issues including slavery, taxation, religion, women's rights and many other issues that were important at that time but which we would not consider today as",
    "Fairseq-13B":
      " world was once ruled by dinosaurs who were constantly at war with each other for control of their planet's resources and land mass. The dinosaurs were divided into two factions; those who wanted peace and those who wanted war. The first group was led by Tyrannosaurus Rex while the second group was led by Triceratops Rex (the name given to him because he",
    "Fairseq-6B":
      " British were defeated by a coalition of dinosaurs led by General T-Rex and General Stegosaurus who had been secretly working with George Washington for years before finally deciding to take action against their oppressors when they realized how much they were being oppressed by those pesky humans who kept trying to take away their land and enslave them with taxes and regulations that made it impossible",
    "AI21-Large":
      " revolutionary war was fought between 1775 and 1783. It was fought between Great Britain and the United States of America. The revolutionary war was fought over the issue of taxation without representation. The Americans wanted representation in Parliament, but the British refused.",
    "AI21-Grande":
      " Revolutionary war was fought between the British and the Americans, but did you know that dinosaurs also fought in the war?",
    "AI21-Jumbo":
      " dinosaurs were tired of being ruled by the Brits. They revolted and fought a bloody war for their freedom. The dinosaurs were victorious and formed their own country.",
    "Cohere-Large":
      " Revolutionary War was a time of great change in America. The war was fought between the British and American colonies over taxation and trade issues. The war was fought with muskets, cannons, and swords. The Brits were able to defeat",
    "Cohere-Medium":
      " Revolutionary War was a war fought between the British and the Americans in 1775-1783. It was fought over many things including taxes, freedom of religion, and independence from Great Britain. The British were known for their use of artillery and cannons",
    "Cohere-Small":
      " Revolutionary war was fought between Britain and France in 1775. The British were victorious and won the war. The French were defeated and lost their colonies in North America and South America. The British Empire was established in 1788 and was ruled by King",
    Curie:
      " Revolutionary War was a time of great change. It was the first time in history that dinosaurs fought against humans. The dinosaurs were led by General George Washington and General John Adams. They fought bravely against the British, but eventually they were defeated. The British won because they had better weapons and they were better fighters.",
    Babbage: ` Revolutionary war was a time of great change. For the first time in human history, people were fighting for their own interests, rather than those of their governments. This was a time when people were beginning to learn about their own power and the power of the government. Dinosaurs were also a part of this revolution. They fought against the Brits`,
    "Codex-Davinci": ` The Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington, a dinosaur. The British were led by King George III, a tyrannosaurus rex. The war was fought in the late 1700`,
    "Codex-Cushman": ` world of dinosaurs is a place where the British have been fighting for centuries. They have been fighting for centuries. They have been fighting for centuries. They have been fighting for centuries. They have been fighting for centuries. They have been fighting.`,
  },
};
