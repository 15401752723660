/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";

import { useEffect, useState, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const PromptModelDisplay = ({
  datafile,
  maxHeight = "400px",
  showLabel = true,
}) => {
  const prompts = datafile.prompts;
  const possiblePrompts = Object.keys(datafile?.options || {});
  const possibleModels = Object.keys(
    datafile?.options?.[possiblePrompts?.[0]] || []
  );
  const [currentModel, setCurrentModel] = useState(possibleModels?.[0] || "");
  const [currentPrompt, setCurrentPrompt] = useState(
    possiblePrompts?.[0] || ""
  );

  const currentExample = datafile?.options?.[currentPrompt]?.[currentModel];

  return (
    <OuterContainer>
      <div
        style={{
          width: "100%",
          overflow: "visible",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            verticalAlign: "center",
            maxWidth: "calc(100vw - 50px)",
          }}
        >
          <div
            style={{
              marginRight: "10px",
              verticalAlign: "center",
              textAlign: "center",
              paddingTop: "5px",
              width: "100px",
            }}
          >
            Model{" "}
          </div>

          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginRight: "20px",
            }}
          >
            <Dropdown
              options={possibleModels}
              onChange={(model) => setCurrentModel(model.value)}
              value={currentModel}
              placeholder="Select a Model"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: "calc(100vw - 50px)",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            verticalAlign: "center",
            textAlign: "center",
            paddingTop: "5px",
            width: "100px",
          }}
        >
          Prompt{" "}
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginRight: "20px",
          }}
        >
          <Dropdown
            options={possiblePrompts}
            onChange={(prompt) => setCurrentPrompt(prompt.value)}
            value={currentPrompt}
            placeholder="Select a Prompt"
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight,
          maxWidth: "100%",
          overflowY: "scroll",
        }}
      >
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={
            prompts?.json?.replace(
              "INFO",
              datafile?.conversionDict?.[currentPrompt]
            ) + " "
          }
          completion={currentExample?.json?.text}
          key={"firstArea"}
          label={showLabel && "JSON Formatted"}
        />
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={prompts?.simple?.replace("INFO", currentPrompt) + " "}
          completion={currentExample?.simple?.text}
          key={"secondArea"}
          label={showLabel && "Freeflow"}
        />
      </div>
    </OuterContainer>
  );
};

export default PromptModelDisplay;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
  margin: auto;
`;

const PrompCompletionArea = ({ prompt, completion, maxHeight, label }) => {
  const parentRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }, 0);
  }, [completion]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}
    >
      <div
        style={{
          position: "relative",
          display: "inline-block",
          padding: "15px",
          border: "1px solid black",
          width: "325px",
          maxHeight,
          overflowY: "auto",
          margin: "5px",
        }}
        ref={parentRef}
      >
        <span
          style={{
            display: "inline",
            fontStyle: "italic",
            whiteSpace: "pre-wrap",
          }}
        >
          {prompt}
        </span>
        <span
          style={{
            display: "inline",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          {completion}
        </span>
      </div>
      {label && <div style={{ fontStyle: "italic" }}>{label}</div>}
    </div>
  );
};
