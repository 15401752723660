/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";

import { useEffect, useState, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const ComparisonDisplay = ({
  datafile1,
  datafile2,
  maxHeight = "400px",
  showLabel = true,
}) => {
  const possibleModels = Object.keys(datafile1?.options || []);

  const [currentModel, setCurrentModel] = useState(possibleModels?.[0] || "");
  console.log(currentModel);

  return (
    <OuterContainer>
      <div style={{ overflow: "visible", maxWidth: "calc(100vw - 50px)" }}>
        <Dropdown
          options={possibleModels}
          onChange={(model) => setCurrentModel(model.value)}
          value={currentModel}
          placeholder="Select a Model"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight,
        }}
      >
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={datafile1?.prompt}
          completion={datafile1?.options?.[currentModel]}
          key={"firstArea"}
          label={showLabel && datafile1?.label}
        />
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={datafile2?.prompt}
          completion={datafile2?.options?.[currentModel]}
          key={"secondArea"}
          label={showLabel && datafile2?.label}
        />
      </div>
    </OuterContainer>
  );
};

export default ComparisonDisplay;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
  width: 750px;
  margin: auto;
`;

const PrompCompletionArea = ({ prompt, completion, maxHeight, label }) => {
  const parentRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }, 0);
  }, [completion]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}
    >
      <div
        style={{
          position: "relative",
          display: "inline-block",
          padding: "15px",
          border: "1px solid black",
          width: "325px",
          maxHeight,
          overflowY: "auto",
          margin: "5px",
        }}
        ref={parentRef}
      >
        <span
          style={{
            display: "inline",
            fontStyle: "italic",
            whiteSpace: "pre-wrap",
          }}
        >
          {prompt}
        </span>
        <span
          style={{
            display: "inline",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          {completion}
        </span>
      </div>
      {label && <div style={{ fontStyle: "italic" }}>{label}</div>}
    </div>
  );
};
