export const SimpleFormatted1Shot = {
  title: "Dinosaurs in the Revoltionary War",
  label: "Freeflow",
  prompt: `Purpose: A conclusion to an essay
Topic: Discussing whether pineapple belongs on pizza
Theme: Arguing pineapple does not belong on pizza
Text: As I have shown, there is no possible way any sane person should have pineapple on pizza. Pineapple is an affront to both the traditional seafood that one might find on pizza as well as to the more modern foods such as pepperoni and anchovies. While there may be some rare edge cases in which pineapple is acceptable as a topping, in the general case it simply is not appropriate.
***
Purpose: An introduction to an essay
Topic: The revolutionary war
Theme: A universe where Dinosaurs fought the Revolutionary war against the Brits
Text: The`,
  options: {
    Davinci:
      " Revolutionary War was fought by brave Americans against the British. But what if the war had been fought by dinosaurs?",
    "GPT-J-6B":
      " Revolutionary War was a war fought between the United States of America and Great Britain. It was fought from 1775-1783 and was one of the most important events in American history. It was also one of the most important events in British history, but for different reasons. The British were fighting for their own independence from their mother country, while Americans were fighting for their freedom from a tyrannical government that had been imposed upon them by King George III.",
    "Cassendra-2-7B":
      " ichnogenus of dinosaurs was a group of animals that lived during the Mesozoic Era, roughly from about 200 million years ago until 65 million years ago. They were herbivores that were related to mammals and reptiles. They had long necks and tails, and their bodies were covered with feathers or fur. Their closest living relatives are birds, which are also herbivores but have feathers instead of fur. Dinosaurs are often considered to be one of",
    "Cassentra-6-7B":
      " American Revolution was a time of great change for our nation. It was a time of great conflict between two very different ideologies, and it was a time of great change for our nation's people. In this essay I will discuss how these changes affected our nation's people and how they affected our nation's history.",
    "GPT-Neo-2-7B":
      " Revolutionary War was a war fought between two nations, England and America. It was fought over many different issues, but one of them was over who would control the land that we now call America. The British were trying to take control of this land from the Native Americans who had lived there for thousands of years. The Native Americans were fighting back by attacking British ships and killing many of them. This caused a lot of anger in England and they decided to attack America",
    "Fairseq-13B":
      " revolutionary war was a time of great change for America. It was a time when our nation was forged into what it is today. It was also a time of great conflict between two very different species, dinosaurs and humans. While many people think of this conflict as being between the British and Americans, this is not entirely true. In fact, it was actually between two very different species of dinosaur, those who were loyal to King George III and those who were loyal",
    "Fairseq-6B":
      " British were defeated by a coalition of dinosaurs and their allies, including giant sloths, saber-toothed cats, and giant ground sloths. The British were forced to sign a treaty with the dinosaurs that allowed them to keep their colonies in exchange for giving up their weapons of mass destruction. This treaty was signed by George Washington himself. The dinosaurs then went on to conquer all of North America and Europe, establishing a new world order where they ruled over",
    "AI21-Large":
      " revolutionary war was fought between 1775 and 1783. The Americans, led by George Washington, were fighting against the British, led by King George III. The Americans won, and King George III was forced to sign the Treaty of Paris, which officially ended the war.",
    "AI21-Grande":
      " revolutionary war was fought between the British and the Americans, but in an alternate universe, it was fought between dinosaurs and the British. The dinosaurs were led by a Tyrannosaurus Rex named “T-Rex”, who was the fiercest dinosaur that ever lived. The dinosaurs were led by a Tyrannosaurus Rex named “T-Rex”, who was the fiercest dinosaur that ever lived. The dinosaurs were led by a Tyrannosaurus Rex named “T-Rex”, who was the fiercest dinosaur that ever lived. The dinosaurs were led by a Tyrannosaurus Rex",
    "AI21-Jumbo":
      " dinosaurs were the dominant species on Earth for millions of years. They ruled the planet, and humans, as inferior creatures, were little more than slaves. However, the dinosaurs were overthrown by a group of humans, who, after many bloody battles, were able to overthrow the dinosaurs and claim the Earth as their own.",
    "Cohere-Large":
      " Revolutionary war was a time of great change in America. The war was fought between the British and the Americans, and it was a time of great change for both sides. The British were fighting to keep their colonies in America, and they were fighting to",
    "Cohere-Medium":
      " Revolutionary War was a war fought between the British and the Americans. The Americans were fighting for freedom from British rule, while the British were fighting for their own freedom from the Americans. The war lasted for about four years, and it was a very bloody",
    "Cohere-Small":
      " revolutionary war was fought between the British and the Americans. The British were victorious in the battle of Waterloo and won the war. The American victory was due to their superior military strength and their ability to fight with a much larger army than the British.",
    Curie:
      " year is 1775. The British Empire, a mighty and opulent land, is at its height. However, a group of rebels, led by George Washington, are determined to overthrow the king and liberate America from British rule.",
    Babbage: ` Revolutionary War was a moment in history that has been largely forgotten. Yet, it was one of the most important wars in American history. fought between America and the British Empire. The war was fought for independence from Great Britain, and it left a significant impact on the development of America.`,
    "Codex-Davinci": ` The Revolutionary War was a war fought between the British and the Americans. The Americans were led by George Washington, who was a dinosaur. The British were led by King George, who was also a dinosaur. The war was fought in the United States, which at the time was called "The New World".`,
    "Codex-Cushman": ` world is a dangerous place. It is full of monsters, and they are everywhere.`,
  },
};
