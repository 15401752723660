import styled from "@emotion/styled";
import { FEATURE_FLAGS } from "../../utils/featureFlags";

const HomePost = () => {
  const stableDiffusionAvailable = FEATURE_FLAGS.stableDiffusion;
  const chatbotAvailable = FEATURE_FLAGS.chatbot;
  const wicAvailable = FEATURE_FLAGS.wic;
  return (
    <OuterContainer>
      <Container>
        {wicAvailable && (
          <>
            <ClickableTitle
              onClick={() => (window.location.href = "/word-in-context")}
            >
              Prompt Fun: Davinci Could Always do Word in Context
            </ClickableTitle>
            <DateSection>February 28, 2023</DateSection>
            <TextSection>
              Since OpenAI released GPT3, it's been common knowledge that their
              Davinci model couldn't do the NLP task "Word in Context". Learn
              some fun tricks to see how every version of Davinci has actually
              been capable of performing this task!
            </TextSection>
            <ClickableTextSection
              onClick={() => (window.location.href = "/word-in-context")}
            >
              {" "}
              Read More
            </ClickableTextSection>
            <SpacerDiv />
            <SpacerDiv />
          </>
        )}
        {chatbotAvailable && (
          <>
            <ClickableTitle
              onClick={() =>
                (window.location.href = "/hallucinating-functions-for-chat")
              }
            >
              Accelerating Development with LLMs by Hallucinating Functions
            </ClickableTitle>
            <DateSection>January 24, 2023</DateSection>
            <TextSection>
              Utilizing a code-based theme for prompt engineering with
              text-davinci-003 enables rapid development by predicting the
              output of arbitrary function names. This allows us to swiftly
              prototype and deploy new features, while continuously refining
              them based on specific task requirements.
            </TextSection>
            <ClickableTextSection
              onClick={() =>
                (window.location.href = "/hallucinating-functions-for-chat")
              }
            >
              {" "}
              Read More
            </ClickableTextSection>
            <SpacerDiv />
            <SpacerDiv />
          </>
        )}

        {stableDiffusionAvailable && (
          <>
            <ClickableTitle
              onClick={() => (window.location.href = "/stable-diffusion")}
            >
              GuidingAutomating Stable Diffusion Prompting
            </ClickableTitle>
            <DateSection>October 10, 2022</DateSection>
            <TextSection>
              Stable Diffusion is an amazing model capable of generating highly
              detailed images. In its most basic form, it takes a prompt (a
              short description of the desired image) and produces an image
              based on that prompt. However, it's not reasonable to expect most
              people to become expert prompt engineers. We've simplified using
              stable diffusion in the Hyperwrite extension by first passing user
              input through a GPT formatting step.
            </TextSection>
            <ClickableTextSection
              onClick={() => (window.location.href = "/stable-diffusion")}
            >
              {" "}
              Read More
            </ClickableTextSection>
            <SpacerDiv />
            <SpacerDiv />
          </>
        )}
        <ClickableTitle
          onClick={() => (window.location.href = "/formatting-llms")}
        >
          Guiding Large Language Models with JSON
        </ClickableTitle>
        <DateSection>June 7, 2022</DateSection>
        <TextSection>
          It's easy to get language models to generate text, not so easy to get
          them to output specific pieces of information given arbitrary inputs.
          We've found JSON formatting can help models keep track of what
          information to include, but this helps larger models more than smaller
          ones. (And mostly Davinci/Curie/Fairseq series)
        </TextSection>
        <ClickableTextSection
          onClick={() => (window.location.href = "/formatting-llms")}
        >
          {" "}
          Read More
        </ClickableTextSection>
        <SpacerDiv />
        <SpacerDiv />

        <ClickableTitle onClick={() => (window.location.href = "/prompts")}>
          Prompts
        </ClickableTitle>
        <DateSection>May 27, 2022</DateSection>
        <TextSection>
          We figured we should throw up a blog post explaining what LLMs are and
          how we can prompt them to generate stuff.
        </TextSection>
        <ClickableTextSection
          onClick={() => (window.location.href = "/prompts")}
        >
          {" "}
          Read More
        </ClickableTextSection>
        <SpacerDiv />
        <SpacerDiv />

        <Title>We Started a Blog</Title>
        <DateSection>May 27, 2022</DateSection>
        <TextSection>Welcome to our blog!</TextSection>
        <TextSection>
          We realized that we wanted to write a bit more about what we're doing
          and how so we figured we'd throw this up to share. At some point our
          designers will catch on and make us fix this, but for now it's a bit
          bare bones the way we like it :)
        </TextSection>
        <TextSection>
          Right now, we're dealing with several engineering challenges that we
          think are pretty interesting:
          <ul>
            <li>
              {" "}
              Figuring out how to generate text with large language models
            </li>
            <li>
              {" "}
              Figuring out how to search for related information to current
              stuff
            </li>
            <li>
              {" "}
              Distributed infrastructure for routing queries to the correct GPU
              cluster
            </li>
            <li> Real time analytics of usage</li>
          </ul>
        </TextSection>
        <TextSection>
          We'll be posting here occasionally as we figure things out as well as
          discussing some of the challenges we're running into. Feel free to
          contact us at support@hyperwrite.ai if there's anything we should
          cover or things that pique your interest.
        </TextSection>
        <SpacerDiv />
        <SpacerDiv />
      </Container>
    </OuterContainer>
  );
};

export default HomePost;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100vh;
  max-width: 750px;
  display: box;
  padding: 10px 30px 30px 30px;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const ClickableTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
`;

const TextSection = styled.div`
  display: block;
  align-items: left;
  margin-bottom: 20px;
  white-space: pre-wrap;
`;

const ClickableTextSection = styled.div`
  display: block;
  align-items: left;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SpacerDiv = styled.div`
  height: 20px;
`;

const DateSection = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;
