import styled from "@emotion/styled";

const Prompts = () => {
  return (
    <OuterContainer>
      <Container>
        <Title>Prompts</Title>
        <DateSection>May 27, 2022</DateSection>
        <IntroSection style={{ marginBottom: "0" }}>
          A lot of what we do uses large language models and prompts, so we
          figured we'd give a bit of an overview of how this all works for those
          interested.
        </IntroSection>
        <IntroSection style={{ marginBottom: "0" }}>
          Over the last several years, access to large language models for
          generation tasks has become widespread. Anyone with a simple internet
          connection can now access giant models from companies such as{" "}
          <a href="https://www.goose.ai">Goose</a>,{" "}
          <a href="https://openai.com">OpenAI</a>,{" "}
          <a href="https://cohere.ai/">Cohere</a>, and{" "}
          <a href="https://www.ai21.com/studio">AI21</a> with the click of a
          button. As access to these models becomes ubiquitous, it's probably
          useful to know how to direct them to generate you want them to get.
          The method of getting a trained model to generate text for you is
          called prompt engineering.
        </IntroSection>
        <IntroSection style={{ marginTop: "0" }}>
          There's a million papers and blog posts on how transformers work but
          it's worth mentioning that the current trend in language models is
          based on the transformers architecture from{" "}
          <a href="https://arxiv.org/abs/1706.03762">
            Attention is All You Need
          </a>{" "}
          which was shown to scale up nicely in{" "}
          <a href="https://arxiv.org/abs/2005.14165">
            Language Models are Few-Shot Learners
          </a>{" "}
          which are worth reading but the techinical details are pretty
          irrelevant for this blog post.
        </IntroSection>
        <SectionHeader>What we mean when we talk about prompts</SectionHeader>
        <TextSection>
          When interacting with large language models (LLMs), you give the model
          some text, called a <i>prompt</i> (or <i>context</i>) for which the
          model generates some text which is called a <i>completion</i>. For
          instance, if you give a LLM a prompt of{" "}
          <CodeBlock>1 + 1 = </CodeBlock>, the model will generally complete
          your prompt with an output, <CodeBlock>2</CodeBlock>. Similar, when
          you give the AI a prompt of{" "}
          <CodeBlock>The capital of France is </CodeBlock>, the model will
          complete it with <CodeBlock>Paris</CodeBlock>.
        </TextSection>
        <TextSection>
          What the model is doing under the hood from those papers we skipped is
          converting those sequences of characters, e.g.{" "}
          <CodeBlock>The capital of France</CodeBlock> into arrays of integers
          called <i>tokens</i> over which the model actually computes such as{" "}
          <CodeBlock>[42, 12, 54, 12, 11]</CodeBlock> that represent the
          characters depending on how the model was trained. It's then
          predicting a sequence of output integers such as{" "}
          <CodeBlock>[51, 55,12]</CodeBlock> which are then converted back into
          characters, e.g. <CodeBlock>Paris</CodeBlock>. Depending on the size
          of the model, you can get between 1,000-8,000 of these tokens between
          the prompt and completion.
        </TextSection>
        <TextSection>
          Now, this works fine if you're just having the model generate simple
          trivia questions. Depending on how large the model is and some other
          issues with training, it may or may not be able to get simple trivia
          or math questions right or wrong just based on the implicit
          information that the model learned in training. However, if we're
          trying to do more complex tasks, that's where things get a little more
          complicated.
        </TextSection>
        <TextSection>
          One of the cool things about these models is that they have really
          interesting emergent properties as a result of being really big. What
          does emergent mean? Well, when you're training one of these things
          you're teaching the model to predict the completion based on the
          prompt. In order to do really well at predicting what's gonna happen
          next, the model has to get really good at picking up underlying
          meanings in the text it's being trained on. So you end up with things
          that have existing benchmarks to test against like the ability to
          distinguish words in context, generate summaries, and pick out answers
          to questions. However, these are considered emergent because no one
          ever told the model to learn these tasks, the model just kind of
          picked it up
        </TextSection>
        <TextSection>
          Prompts can be quite powerful as long as the model has picked up on
          the relations between all the target entities. For instance, let's say
          we want to have a model always respond to us with the capital of a
          country. We can create a prompt that we prepend to an input as such:
          <CodeBlock>
            <br /> Germany
            <br /> Berlin
            <br /> ---
            <br /> Italy
            <br /> Rome
            <br /> ---
            <br /> France
            <br />
          </CodeBlock>
          And the model will likely put out Paris (as long as it knows Paris is
          the capital of France which a well trained model should).
        </TextSection>
        <TextSection>
          While powerful and generalizable, there's some limitations to
          prompting. First, you can only include a certain number of examples
          with each generation because we're limited in the size of the prompt
          to approximately 1,000-2,000 tokens. Second, we have to include this
          prompt every time we query the model, which can take some time
          (although computing input tokens is pretty fast). However, depending
          on platforms, the provider may charge for the input tokens so it can
          lead to higher costs than you might want if you've got large prompts
          but small completions.
        </TextSection>
        <SectionHeader>Fine Tuning</SectionHeader>
        <TextSection>
          In contrast to prompt engineering, you can train desired abilities
          into the model via fine tuning.
        </TextSection>
        <TextSection>
          Basically once the model's trained, we go and teach it either new data
          or tasks by having the model train on new input. We can fine tune in
          prompts so that way we don't need to do as much work with examples to
          get it to work.
        </TextSection>
        <TextSection>
          For instance, let's say we want the model to always just tell us
          capitals. We can go grab a bunch of examples of answering capital
          questions, e.g. consider our list of capitals from before.
          <br />
          <CodeBlock>
            France
            <br />
            Paris <br />
            ---
            <br /> Germany
            <br /> Berlin
            <br /> ---
            <br /> Italy
            <br /> Rome
            <br /> ---
            <br /> ...
            <br />
          </CodeBlock>
          As opposed to the ephemeral nature of prompts, we can then re-train
          the model on this list of inputs/outputs so it's a permanent fixture
          of the model that whenever we give it a country it'll spit out the
          capital immediately. However, we can lose some capabilities of the
          model by doing this (e.g. completing text prompts) so there's always
          tradeoffs between training and generalizability.
        </TextSection>
        <SectionHeader>Well, that's it for now</SectionHeader>
        <TextSection>
          This is mainly intended as a reference for the things we don't want to
          have to re-hash in our subsequent posts so as questions come up with
          things we should cover here we'll be adding them. Don't hesitate to
          contact us at{" "}
          <a href="mailto:support@hyperwrite.ai">support@hyperwrite.ai</a> with
          any comments!
        </TextSection>
      </Container>
    </OuterContainer>
  );
};

export default Prompts;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

const Container = styled.div`
  height: 100vh;
  max-width: 750px;
  display: box;
  padding: 10px 30px 30px 30px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const TextSection = styled.div`
  display: block;
  align-items: left;
  margin-bottom: 20px;
`;

const CodeBlock = styled.code`
  align-items: left;
  margin-bottom: 20px;
`;

const IntroSection = styled.div`
  display: block;
  align-items: left;
  margin-bottom: 20px;
  //gray
  background-color: #f0f0f0;
  padding: 10px;
`;

const SectionHeader = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const SpacerDiv = styled.div`
  height: 20px;
`;

const DateSection = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;
